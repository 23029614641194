import MuiDialog from "@mui/material/Dialog"
import DialogTitle from "@mui/material/DialogTitle"
import DialogContent from "@mui/material/DialogContent"
import DialogActions from "@mui/material/DialogActions"
import TextField from "@/components/TextField"
import NiceModal, { useModal } from "@ebay/nice-modal-react"
import InlineContainer from "@/components/InlineContainer"
import IconButton from "@mui/material/IconButton"
import Typography from "@mui/material/Typography"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import getFieldErrors from "@/utils/getFieldErrors"
import { useFormik } from "formik"
import { useTheme, useMediaQuery, MenuItem } from "@mui/material"
import CloseIcon from "@mui/icons-material/CloseRounded"
import NumberField from "@/components/NumberField"
import { CardSurchargeToEnum, DialogKeys, Query } from "@/types"
import { getIntFromDollars } from "@/utils/calculations"
import unformatNumber from "@/utils/unformatNumber"
import AffirmativeButton from "@/components/buttons/AffirmativeButton"
import NeutralButton from "@/components/buttons/NeutralButton"
import { useSnackbar } from "notistack"
import Select from "@/components/Select"
import { pufCreationValidationSchema } from "../admin-management-validation"
import { createPufAccount } from "../admin-management-queries"

export default NiceModal.create(({ partnershipId }: { partnershipId: string }) => {
  const modal = useModal(DialogKeys.ADMIN_PUF_CREATION)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("md"))
  const { enqueueSnackbar } = useSnackbar()
  const queryClient = useQueryClient()

  const { mutate: createPuf, isLoading } = useMutation((values: any) => createPufAccount({ partnershipId, values }), {
    onSuccess: () => {
      queryClient.invalidateQueries([Query.admin.SPECIFIC_PARTNER])
      modal.resolve()
      modal.hide()
      window.location.reload()
    },
    onError: (e) => {
      enqueueSnackbar(
        // @ts-ignore
        e?.response?.data?.message || "Could not create puf account",
        {
          variant: "error",
        },
      )
    },
  })

  const formik = useFormik<any>({
    initialValues: {
      ein: "",
      saas_priceitem_name: "",
      saas_priceitem_price: 0,
      card_surcharge_to: CardSurchargeToEnum.USER,
      saas_agreement_copy: "",
      routing_number: "",
      account_number: "",
    },
    validationSchema: pufCreationValidationSchema,
    onSubmit: (values) => {
      const payload = {
        ein: values.ein,
        saas_priceitem_name: values.saas_priceitem_name,
        saas_priceitem_price: getIntFromDollars(unformatNumber(values.saas_priceitem_price)),
        card_surcharge_to: values.card_surcharge_to,
        saas_agreement_copy: values.saas_agreement_copy,
        payout_bank_account: {
          routing_number: values.routing_number,
          account_number: values.account_number,
        },
      }
      createPuf(payload)
    },
  })

  const handleCloseModal = () => {
    modal.reject()
    modal.hide()
  }

  return (
    <MuiDialog
      open={modal.visible}
      maxWidth="sm"
      fullWidth
      fullScreen={isMobile}
      TransitionProps={{
        onExited: () => {
          formik.resetForm()
          modal.remove()
        },
      }}
    >
      <DialogTitle sx={{ padding: "0.75rem 1.25rem" }}>
        <InlineContainer justifyContent="space-between">
          <Typography
            variant="h6"
            color="primary"
            sx={{
              paddingLeft: { xs: "30px", md: 0 },
              textAlign: { xs: "center", md: "left" },
              width: "100%",
            }}
          >
            Create a Partner-Using-Finli (PuF) Account
          </Typography>
          <IconButton
            aria-label="close"
            size="small"
            onClick={handleCloseModal}
            sx={{
              color: (theme) => theme.palette.grey[500],
              position: "relative",
              right: -6,
            }}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </InlineContainer>
      </DialogTitle>
      <DialogContent sx={{ padding: "1.25rem" }} dividers>
        <form onSubmit={formik.handleSubmit} id="puf-form">
          <TextField
            size="small"
            color="primary"
            variant="outlined"
            fullWidth
            label="EIN"
            sx={{ fontSize: "1rem", marginBottom: "1.25rem" }}
            {...formik.getFieldProps("ein")}
            {...getFieldErrors("ein", formik)}
            onChange={(e) => {
              const v = e.target.value.replace(/\D/g, "").slice(0, 9)
              formik.setFieldValue(
                "ein",
                v.length >= 3 ? [v.replace(/\D/g, "").slice(0, 2), v.replace(/\D/g, "").slice(2)].join("-") : v,
              )
            }}
          />
          <TextField
            size="small"
            color="primary"
            variant="outlined"
            fullWidth
            label="SaaS Item Name"
            sx={{ fontSize: "1rem", marginBottom: "1.25rem" }}
            {...formik.getFieldProps("saas_priceitem_name")}
            {...getFieldErrors("saas_priceitem_name", formik)}
          />
          <NumberField
            size="small"
            color="primary"
            variant="outlined"
            fullWidth
            label="SaaS Fee Amount"
            sx={{ fontSize: "1rem", marginBottom: "1.25rem" }}
            {...formik.getFieldProps("saas_priceitem_price")}
            {...getFieldErrors("saas_priceitem_price", formik)}
            onChange={(e) => {
              formik.setFieldValue("saas_priceitem_price", +e.target.value)
            }}
          />
          <Select
            size="small"
            color="primary"
            variant="outlined"
            fullWidth
            label="Select who will pay the 3% credit card transaction fee."
            sx={{ fontSize: "1rem", marginBottom: "1.25rem" }}
            inputProps={{
              tabIndex: 2,
            }}
            {...formik.getFieldProps("card_surcharge_to")}
            {...getFieldErrors("card_surcharge_to", formik)}
          >
            <MenuItem value={CardSurchargeToEnum.ACCOUNT}>Partner</MenuItem>
            <MenuItem value={CardSurchargeToEnum.USER}>Partner's SMB clients</MenuItem>
          </Select>
          <TextField
            size="small"
            color="primary"
            variant="outlined"
            fullWidth
            label="SaaS Agreement Copy"
            sx={{ fontSize: "1rem" }}
            multiline
            rows={3}
            id="saas_agreement_copy"
            {...formik.getFieldProps("saas_agreement_copy")}
            inputProps={{
              maxLength: 200,
              rows: 3,
            }}
          />
          <Typography
            variant="body2"
            sx={{
              color: formik?.values?.saas_agreement_copy?.length >= 200 ? "error.dark" : "grey.600",
              margin: "0.5rem 0 0.5rem 0",
              textAlign: "right",
            }}
          >
            {formik?.values?.saas_agreement_copy?.length ?? 0} / 250
          </Typography>
          <TextField
            size="small"
            color="primary"
            variant="outlined"
            fullWidth
            label="(Payout Bank Account) Routing Number"
            sx={{ fontSize: "1rem", marginBottom: "1.25rem" }}
            {...formik.getFieldProps("routing_number")}
            {...getFieldErrors("routing_number", formik)}
            onChange={(e) => {
              formik.setFieldValue("routing_number", e.target.value.replace(/\D/g, "").slice(0, 9))
            }}
          />
          <TextField
            size="small"
            color="primary"
            variant="outlined"
            fullWidth
            label="(Payout Bank Account) Account Number"
            sx={{ fontSize: "1rem", marginBottom: "1.25rem" }}
            {...formik.getFieldProps("account_number")}
            {...getFieldErrors("account_number", formik)}
            onChange={(e) => {
              formik.setFieldValue("account_number", e.target.value.replace(/\D/g, ""))
            }}
          />
        </form>
      </DialogContent>
      <DialogActions sx={{ padding: "0.75rem 1.25rem" }}>
        <InlineContainer justifyContent="space-between" width="100%">
          <NeutralButton onClick={handleCloseModal} sx={{ marginRight: 2 }} disableElevation>
            Cancel
          </NeutralButton>
          <AffirmativeButton
            type="submit"
            form="puf-form"
            disabled={isLoading}
            sx={{
              whiteSpace: "nowrap",
              width: { xs: 140, md: 140 },
            }}
            loading={isLoading}
          >
            Save
          </AffirmativeButton>
        </InlineContainer>
      </DialogActions>
    </MuiDialog>
  )
})
