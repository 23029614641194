import DeprecatedAvatarUploader from "@/components/DeprecatedAvatarUploader"

const EditableAvatar = ({ isEditable, avatar_url, public_name }) => {
  if (isEditable) {
    return (
      <DeprecatedAvatarUploader
        size={120}
        orientation="vertical"
        currentAvatar={avatar_url}
        showButtons={false}
        showAsSquare={true}
      />
    )
  } else if (avatar_url) {
    return (
      // eslint-disable-next-line @next/next/no-img-element
      <img
        style={{
          objectFit: "contain",
          height: 100,
          width: 100,
        }}
        src={avatar_url}
        alt={public_name}
      />
    )
  }

  return null
}

export default EditableAvatar
