import AffirmativeButton from "@/components/buttons/AffirmativeButton"
import Box from "@mui/material/Box"
import Checkbox from "@mui/material/Checkbox"
import FormattedPhone from "@/components/FormattedPhone"
import InlineContainer from "@/components/InlineContainer"
import NeutralButton from "@/components/buttons/NeutralButton"
import NiceModal, { useModal } from "@ebay/nice-modal-react"
import PlusIcon from "@mui/icons-material/AddRounded"
import TextField from "@/components/TextField"
import Typography from "@mui/material/Typography"
import groupBy from "lodash/groupBy"
import sortBy from "lodash/sortBy"
import useAppDispatch from "@/hooks/useAppDispatch"
import useAppSelector from "@/hooks/useAppSelector"
import { Dialog, DialogContent, DialogActions } from "@/components/Dialog"
import { Query, DialogKeys, AccountStatuses, InvoiceCustomer, QuoteCustomer } from "@/types"
import { fetchAllCustomers } from "@/features/customer-management/customer-management-queries"
import { matchSorter } from "match-sorter"
import { resetInvoice, setInvoice } from "@/features/invoice-management/invoice-management-reducers"
import { selectInvoice } from "@/features/invoice-management/invoice-management-selectors"
import { styled } from "@mui/material"
import { useQuery } from "@tanstack/react-query"
import { useRouter } from "next/router"
import { useSnackbar } from "notistack"
import { useState, Fragment } from "react"
import { useTheme, useMediaQuery } from "@mui/material"
import { selectQuote } from "../quote-management/quote-management-selectors"
import { setQuote } from "../quote-management/quote-management-reducers"

const CustomerGroup = styled(Box)(
  ({ theme }) => `
  background-color: ${theme.palette.grey[200]};
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${theme.spacing(1)};
`,
)

const CustomerCard = styled(InlineContainer)(
  ({ theme }) => `
  background-color: ${theme.palette.common.white};
  border-radius: ${theme.shape.borderRadius}px;
  border: 1px solid ${theme.palette.common.white};
  cursor: pointer;
  padding: 0.75rem 1rem;
  width: 75%;

  &:hover {
    border: 1px solid ${theme.palette.primary.main};
  }
  
  &.selected {
    border: 2px solid ${theme.palette.primary.main};
  }

  .MuiTypography-body1 {
    color: ${theme.palette.common.black};
    font-weight: 500;
  }

  .MuiTypography-body2 {
    color: ${theme.palette.grey[700]};
  }
`,
)

const BatchInvoiceCard = styled(InlineContainer)(
  ({ theme }) => `
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: .75rem;
  background-color: ${theme.palette.common.white};
  border-radius: ${theme.shape.borderRadius}px;
  border: 1px solid ${theme.palette.common.white};
  padding: 0.75rem 1rem;
  width: 75%;
`,
)

const useLimitedArrayState = (
  initialState: any[],
  arrayLimit: number,
  key: string,
): [any[], (newState: any[]) => void] => {
  const [state, setState] = useState(initialState)
  const { enqueueSnackbar } = useSnackbar()

  const updateState = (newState) => {
    if (newState.length > arrayLimit) {
      enqueueSnackbar(`You can only add ${arrayLimit} ${arrayLimit === 1 ? key : `${key}s`} to this list.`, {
        variant: "error",
      })
    } else {
      setState(newState)
    }
  }

  return [state, updateState]
}

const reorganizeCustomer = (customers) => {
  let updatedCustomers: InvoiceCustomer[] = []

  customers?.forEach((customer) => {
    const email = customer?.email || customer?.user?.email
    const cell_phone = customer?.cell_phone || customer?.user?.cell_phone

    updatedCustomers =
      updatedCustomers?.map((existingCustomer) => {
        // Check if the customer already exists
        if (existingCustomer.id === customer.id || existingCustomer.email === email) {
          // If the existing customer matches, update the email and cell_phone
          return {
            ...existingCustomer,
            email,
            cell_phone,
          }
        }
        // If the existing customer doesn't match, return it as it is
        return existingCustomer
      }) ?? []

    // Check if the customer already exists in the array
    const customerExists = updatedCustomers?.some(
      (existingCustomer) => existingCustomer.id === customer.id || existingCustomer.email === email,
    )

    // If the customer doesn't exist, add it to the end of the array
    if (!customerExists) {
      updatedCustomers?.push({
        ...customer,
        email,
        cell_phone,
      })
    }
  })

  return updatedCustomers
}

export default NiceModal.create<{
  prevSelectedCustomers: (InvoiceCustomer | QuoteCustomer)[]
  mode: "invoice" | "quote"
}>(({ prevSelectedCustomers, mode }) => {
  const theme = useTheme()
  const dispatch = useAppDispatch()
  const isMobile = useMediaQuery(theme.breakpoints.down("md"))
  const [search, setSearch] = useState("")
  const modal = useModal()
  const selectedInvoiceQuote = useAppSelector(mode === "invoice" ? selectInvoice : selectQuote)
  const maxCustomerLimit = selectedInvoiceQuote?.id ? 1 : 50
  const [selectedCustomers, setSelectedCustomers] = useLimitedArrayState(
    prevSelectedCustomers ?? [],
    maxCustomerLimit,
    "customer",
  )
  const router = useRouter()

  const { data } = useQuery<{ results?: InvoiceCustomer[] }>(
    [Query.account.BUILDER_ALL_CUSTOMERS],
    () => fetchAllCustomers(),
    {
      keepPreviousData: true,
    },
  )

  const customers = data?.results ?? []
  const activeCustomers = customers.filter((c) => c.status === AccountStatuses.ACTIVE)
  const hasActiveCustomers = activeCustomers?.length > 0

  const searchedCustomers: any = matchSorter(activeCustomers ?? [], search, {
    keys: ["name", "phone", "cell_phone", "email"],
  })

  const handleCloseModal = () => {
    modal.hide()
  }

  // Sort alphabetically by name and split into groups by first letter.
  const sortedCustomers = groupBy(
    sortBy(searchedCustomers ?? [], ["name"]),
    (customer) => Array.from(customer?.name)[0],
  )
  // Got rid of lodash, we were comparing by reference instead of by value which was not performing includes
  // correctly and was forcing the user to click a customer twice to remove them from the array
  const handleSelectCustomer = (customer: InvoiceCustomer) => {
    if (mode === "quote") {
      setSelectedCustomers([customer])
    } else {
      if (selectedCustomers?.some((c) => c?.id === customer?.id)) {
        return setSelectedCustomers(selectedCustomers.filter((i) => i?.id !== customer?.id))
      }

      setSelectedCustomers([...selectedCustomers, customer])
    }
  }

  const handleAddCustomer = (customers: InvoiceCustomer[]) => {
    const updatedCustomers = reorganizeCustomer(customers)

    if (mode === "invoice") {
      if (customers.length === 1) {
        dispatch(
          setInvoice({
            ...selectedInvoiceQuote,
            has_changed: true,
            customers: updatedCustomers,
          }),
        )
      } else {
        NiceModal.show(DialogKeys.INVOICE_CUSTOMER_OPTIONS, {
          prevSelectedCustomers: selectedCustomers,
        })
          .then((customers) => {
            const updatedCustomers = reorganizeCustomer(customers)
            dispatch(
              setInvoice({
                ...selectedInvoiceQuote,
                has_changed: true,
                customers: updatedCustomers,
              }),
            )
          })
          .catch(() => {
            modal.show()
          })
      }
    } else {
      dispatch(
        // @ts-ignore
        setQuote({
          ...selectedInvoiceQuote,
          has_changed: true,
          customers: updatedCustomers,
        }),
      )
    }
    handleCloseModal()
  }

  const handleAddNewCustomer = () => {
    modal.hide()
    NiceModal.show<InvoiceCustomer>(DialogKeys.CUSTOMER, {
      shouldShowBulkImport: false,
    }).then((newCustomer) => {
      const updatedList = [...selectedCustomers].concat(newCustomer)
      modal.show({
        prevSelectedCustomers: updatedList,
        mode,
      })
      setSelectedCustomers(updatedList)
    })
  }

  const CreateNewButton = hasActiveCustomers ? NeutralButton : AffirmativeButton

  return (
    <Dialog modal={modal} title="Customers">
      {!hasActiveCustomers ? (
        <DialogContent sx={{ paddingTop: "3rem" }} dividers>
          <Typography
            variant="body1"
            sx={{
              fontWeight: 500,
              textAlign: "center",
              ...(isMobile ? {} : { marginBottom: "2.5rem" }),
            }}
          >
            Looks like you don't have any customers saved in the system. To create a new customer,{" "}
            <strong>{isMobile ? "tap" : "click"} the "+ Create New" button</strong>.
          </Typography>
        </DialogContent>
      ) : (
        <DialogContent
          sx={{
            padding: { xs: "0 0 5rem 0", md: " 0rem 1.25rem  " },
          }}
          dividers
        >
          <Box
            display="flex"
            flexDirection="column"
            sx={{
              position: "sticky",
              top: 0,
              zIndex: 1,
              alignItems: "center",
              backgroundColor: "grey.200",
              textAlign: "center",
              gap: ".5rem",
            }}
          >
            <Typography
              variant="body1"
              sx={{
                backgroundColor: "grey.500",
                color: "white",
                padding: "0.5rem 1.5rem",
                textAlign: "left",
                width: "100%",
              }}
            >
              Customer List
            </Typography>
            <TextField
              name="customer-search"
              placeholder="Search"
              sx={{
                backgroundColor: "white",
                borderRadius: "0.5rem",
                margin: "1rem",
                width: "75%",
              }}
              onChange={(event) => setSearch(event?.target?.value)}
            />
            {selectedCustomers?.length > 1 && (
              <BatchInvoiceCard marginBottom="1rem">
                <InlineContainer gap=".5rem">
                  <Typography variant="body1" fontWeight={600}>
                    Batch invoice Recipients
                  </Typography>
                  <Typography variant="body1" color="primary" fontWeight={600}>
                    ({selectedCustomers?.length}/{maxCustomerLimit})
                  </Typography>
                </InlineContainer>
                <Typography variant="body2" textAlign="start">
                  Selecting multiple customers creates a Batch Invoice. Each customer receives their own separate
                  invoice and is billed individually.
                </Typography>
              </BatchInvoiceCard>
            )}
            {selectedInvoiceQuote?.id && mode === "invoice" && (
              <BatchInvoiceCard marginBottom="1rem">
                <Typography variant="body2" textAlign="start">
                  Multiple recipients cannot be added to a draft. If you'd like to create a Batch Invoice,{" "}
                  <strong
                    style={{
                      color: theme.palette.primary.main,
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      modal.hide()
                      dispatch(resetInvoice())
                      router.push("/invoices/create")
                    }}
                  >
                    create a new invoice.
                  </strong>
                </Typography>
              </BatchInvoiceCard>
            )}
          </Box>
          <Box>
            {Object.keys(sortedCustomers || {}).map((letter) => (
              <Fragment key={`invoice-list-${letter}`}>
                <Box padding="0.5rem 1.5rem" fontWeight={700}>
                  {letter}
                </Box>
                <CustomerGroup>
                  {sortedCustomers?.[letter]?.map((customer) => {
                    const isSelected = selectedCustomers
                      ?.map((selectedCustomer) => selectedCustomer?.id)
                      ?.includes(customer?.id)
                    const shouldDisableCustomerCard =
                      mode === "invoice" && selectedCustomers?.length === 1 && !!selectedInvoiceQuote?.id && !isSelected

                    return (
                      <CustomerCard
                        key={`invoice-quote-customer-${customer?.id}`}
                        onClick={() => {
                          if (!shouldDisableCustomerCard) {
                            handleSelectCustomer(customer)
                          }
                        }}
                        className={isSelected ? "selected" : ""}
                        sx={{
                          cursor: shouldDisableCustomerCard ? "not-allowed" : "pointer",
                        }}
                      >
                        {mode === "invoice" && (
                          <Box>
                            <Checkbox
                              size="small"
                              sx={{
                                position: "relative",
                                left: "-.5rem",
                              }}
                              checked={isSelected}
                              disabled={shouldDisableCustomerCard}
                            />
                          </Box>
                        )}
                        <Box>
                          <Typography variant="body1">{customer?.name}</Typography>
                          {customer?.email && <Typography variant="body2">{customer?.email}</Typography>}
                          {customer?.cell_phone && (
                            <Typography variant="body2">
                              <FormattedPhone value={customer?.cell_phone} />
                            </Typography>
                          )}
                        </Box>
                      </CustomerCard>
                    )
                  })}
                </CustomerGroup>
              </Fragment>
            ))}
          </Box>
        </DialogContent>
      )}
      <DialogActions
        sx={{
          padding: "0.75rem 1.25rem",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        {hasActiveCustomers && (
          <AffirmativeButton
            disabled={selectedCustomers?.length < 1 || selectedCustomers?.length > maxCustomerLimit}
            sx={{ width: { xs: "100%", md: 350 } }}
            onClick={() => handleAddCustomer(selectedCustomers)}
          >
            Continue
          </AffirmativeButton>
        )}
        <CreateNewButton
          onClick={handleAddNewCustomer}
          startIcon={<PlusIcon fontSize="small" />}
          sx={{ width: { xs: "100%", md: 350 } }}
        >
          Create New
        </CreateNewButton>
      </DialogActions>
    </Dialog>
  )
})
