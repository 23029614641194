import Box from "@mui/material/Box"
import { styled } from "@mui/material"
import Typography from "@mui/material/Typography"
import Link from "@/components/Link"
import InlineContainer from "@/components/InlineContainer"
import Image from "next/legacy/image"
import useUser from "@/features/auth/useUser"
import UserMenu from "@/components/UserMenu"
import NiceModal from "@ebay/nice-modal-react"
import LoginDialog from "@/components/LoginDialog"
import { logoutThunk } from "@/features/auth/auth-queries"
import useAppDispatch from "@/hooks/useAppDispatch"
import SignupDialog from "@/components/SignupDialog"
import useAppSelector from "@/hooks/useAppSelector"
import { selectPartnerTheme } from "../layout-selectors"
import Head from "next/head"

const Header = styled(InlineContainer, {
  shouldForwardProp: (prop) => prop !== "isPartner",
})<{}>(
  ({ theme }) => `
	background-color: ${theme.palette.primary.dark};
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
  height: 72px;
  padding: ${theme.spacing(0, 3)};
`,
)

const Container = styled(Box)(
  ({ theme }) => `
	background-color: ${theme.palette.grey[50]};
	display: flex;
	justify-content: flex-start;
	align-items: center;
  flex-direction: column;
	gap: 0;
  width: 100%;
  min-height: calc(100vh - 72px);
  margin-top: 72px;
  padding: 0 1rem;
`,
)

const GenericLayout = ({ is404 = false, children, hideHeader = false }) => {
  const dispatch = useAppDispatch()
  const { user } = useUser()
  const partnerTheme = useAppSelector(selectPartnerTheme)

  return (
    <>
      <Head>
        {partnerTheme?.fav_icon_url ? (
          <link rel="shortcut icon" type="image/png" href={partnerTheme?.fav_icon_url} />
        ) : (
          <link rel="shortcut icon" href="/favicon.png" />
        )}
      </Head>
      {hideHeader ? null : (
        <Header>
          <InlineContainer justifyContent="space-between" width="100%">
            <InlineContainer>
              {Boolean(partnerTheme?.avatar_url) ? (
                /* eslint-disable-next-line */
                <img src={partnerTheme?.avatar_url as string} alt="Partner Logo" height={40} />
              ) : (
                <Link href="/" color="custom.approved" sx={{ marginTop: "0.25rem" }}>
                  <Image
                    src="/logo-finli-white-transparent.png"
                    width={50}
                    height={25}
                    alt="Finli Logo"
                    style={{ cursor: "pointer" }}
                  />
                </Link>
              )}
              {user?.id ? (
                <Link
                  href="/"
                  sx={{
                    color: "#EAFCD2",
                    fontSize: "0.9rem",
                    marginLeft: 3,
                    "&:hover": {
                      textDecoration: "underline",
                    },
                  }}
                >
                  Return to Dashboard
                </Link>
              ) : is404 ? (
                <Link
                  href="/login"
                  sx={{
                    color: "#EAFCD2",
                    fontSize: "0.9rem",
                    marginLeft: 3,
                    "&:hover": {
                      textDecoration: "underline",
                    },
                  }}
                >
                  Return to Login
                </Link>
              ) : null}
            </InlineContainer>
            {user?.id && !is404 ? (
              <UserMenu />
            ) : user?.id ? (
              <Link
                sx={{
                  color: "#EAFCD2",
                  fontSize: "0.9rem",
                  fontWeight: 600,
                  "&:hover": {
                    textDecoration: "underline",
                  },
                }}
                onClick={async () => {
                  await dispatch(logoutThunk())

                  const timer = setTimeout(() => {
                    clearTimeout(timer)
                    window.location.reload()
                  }, 1000)
                }}
              >
                Logout
              </Link>
            ) : (
              <Box sx={{ color: "white", display: "flex", whiteSpace: "nowrap" }}>
                <Link
                  sx={{
                    color: "#EAFCD2",
                    fontSize: "0.9rem",
                    fontWeight: 600,
                    textDecoration: "none",
                    "&:hover": {
                      textDecoration: "underline",
                    },
                  }}
                  onClick={() => NiceModal.show(LoginDialog)}
                >
                  Login
                </Link>
                <Typography variant="body2" sx={{ margin: "0 0.5rem" }}>
                  or
                </Typography>
                <Link
                  sx={{
                    color: "#EAFCD2",
                    fontSize: "0.9rem",
                    fontWeight: 600,
                    textDecoration: "none",
                    "&:hover": {
                      textDecoration: "underline",
                    },
                  }}
                  onClick={() => NiceModal.show(SignupDialog)}
                >
                  Sign Up
                </Link>
              </Box>
            )}
          </InlineContainer>
        </Header>
      )}
      <Container sx={hideHeader ? { marginTop: "20px" } : {}}>{children}</Container>
    </>
  )
}

export default GenericLayout
