import MuiDialog from "@mui/material/Dialog"
import DialogTitle from "@mui/material/DialogTitle"
import DialogContent from "@mui/material/DialogContent"
import DialogActions from "@mui/material/DialogActions"
import TextField from "@/components/TextField"
import NiceModal, { useModal } from "@ebay/nice-modal-react"
import InlineContainer from "@/components/InlineContainer"
import IconButton from "@mui/material/IconButton"
import Typography from "@mui/material/Typography"
import { useMutation } from "@tanstack/react-query"
import getFieldErrors from "@/utils/getFieldErrors"
import { useFormik } from "formik"
import { useTheme, useMediaQuery } from "@mui/material"
import { useEffect, useState } from "react"
import CloseIcon from "@mui/icons-material/CloseRounded"
import { DialogKeys, PartnershipAdValues } from "@/types"
import SystemFeedback from "@/components/SystemFeedback"
import useMessage from "@/hooks/useMessage"
import AffirmativeButton from "@/components/buttons/AffirmativeButton"
import NeutralButton from "@/components/buttons/NeutralButton"
import { enqueueSnackbar } from "notistack"
import TestId from "@/constants/testIds"
import { DateTime } from "luxon"
import DateRangePicker from "@/components/DateRangePicker"
import ImageUploader from "@/components/ImageUploader"
import { createPartnershipAd, updatePartnershipAd } from "../admin-management-queries"
import { partnershipAdValidationSchema } from "../admin-management-validation"

export default NiceModal.create(
  ({ partnershipId, partnershipAd }: { partnershipId: string; partnershipAd?: PartnershipAdValues }) => {
    const modal = useModal(DialogKeys.PARTNERSHIP_AD)
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down("md"))
    const [message, setMessage] = useMessage()
    const [isSubmit, setIsSubmit] = useState(false)

    const isNew = typeof partnershipAd?.id === "undefined"

    const formik = useFormik<PartnershipAdValues>({
      initialValues: {
        image_src: undefined,
        start_at: undefined,
        end_at: undefined,
        link: "",
      },
      validationSchema: partnershipAdValidationSchema,
      initialErrors: {},
      onSubmit: (values) => {
        setIsSubmit(true)
        if (!isNew) {
          editAd(values)
        } else {
          saveAd(values)
        }
      },
    })

    const { mutate: saveAd, isLoading: isSavingAd } = useMutation(
      (values: PartnershipAdValues) => createPartnershipAd({ partnershipId, values }),
      {
        onSuccess: (response) => {
          enqueueSnackbar("Ad successfully saved", {
            variant: "success",
          })
          modal.resolve(response)
          modal.hide()
        },
        onError: (error: any) => {
          setMessage({
            type: "error",
            message: error?.response?.data?.message ?? "Cannot create ad.",
          })
        },
      },
    )

    const { mutate: editAd, isLoading: isEditingAd } = useMutation(
      (values: PartnershipAdValues) => updatePartnershipAd({ partnershipAdId: partnershipAd?.id as string, values }),
      {
        onSuccess: (response) => {
          enqueueSnackbar("Ad successfully saved", {
            variant: "success",
          })
          modal.resolve(response)
          modal.hide()
        },
        onError: (error: any) => {
          setMessage({
            type: "error",
            message: error?.response?.data?.message ?? "Cannot create ad.",
          })
        },
      },
    )

    useEffect(() => {
      formik.setValues({
        image_src: partnershipAd?.image_src ?? "",
        start_at: partnershipAd?.start_at ?? undefined,
        end_at: partnershipAd?.end_at ?? undefined,
        link: partnershipAd?.link ?? "",
      })
    }, [partnershipAd?.id])

    const handleCloseModal = () => {
      modal.reject()
      modal.hide()
      if (isNew) {
        formik.resetForm()
      }
    }

    const setDuration = (dates) => {
      formik.setFieldValue("start_at", dates?.[0] ? DateTime.fromJSDate(dates?.[0]).toFormat("yyyy-MM-dd") : null)
      formik.setFieldValue("end_at", dates?.[1] ? DateTime.fromJSDate(dates?.[1]).toFormat("yyyy-MM-dd") : null)
    }

    return (
      <MuiDialog
        open={modal.visible}
        maxWidth="sm"
        fullWidth
        fullScreen={isMobile}
        TransitionProps={{
          onExited: () => {
            formik.resetForm()
            modal.remove()
          },
        }}
      >
        <DialogTitle sx={{ padding: "0.75rem 1.25rem" }}>
          <InlineContainer justifyContent="space-between">
            <Typography
              variant="h6"
              color="primary"
              sx={{
                paddingLeft: { xs: "30px", md: 0 },
                textAlign: { xs: "center", md: "left" },
                width: "100%",
              }}
            >
              {isNew ? "Create" : "Edit"} ad
            </Typography>
            <IconButton
              aria-label="close"
              size="small"
              onClick={handleCloseModal}
              sx={{
                color: (theme) => theme.palette.grey[500],
                position: "relative",
                right: -6,
              }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </InlineContainer>
        </DialogTitle>
        <DialogContent sx={{ padding: "1.25rem" }} dividers>
          <form onSubmit={formik.handleSubmit} id="ad-form">
            <SystemFeedback {...message} />
            <Typography variant="body1" sx={{ fontSize: "1rem", margin: "0.5rem 0 0.5rem", fontWeight: 700 }}>
              Image
            </Typography>
            <ImageUploader
              values={formik.values.image_src}
              onChange={(image) => {
                formik.setFieldValue("image_src", image)
              }}
              size={[540, 160]}
            />
            {formik.errors.image_src && isSubmit && (
              <Typography variant="body1" sx={{ fontSize: "0.9rem", margin: "0.5rem 0", color: "error.dark" }}>
                {formik.errors.image_src}
              </Typography>
            )}
            <Typography variant="body1" sx={{ fontSize: "1rem", margin: "1rem 0 0.5rem", fontWeight: 700 }}>
              Duration
            </Typography>
            <DateRangePicker
              value={[
                formik.values.start_at ? DateTime.fromFormat(formik.values.start_at, "yyyy-MM-dd").toJSDate() : null,
                formik.values.end_at ? DateTime.fromFormat(formik.values.end_at, "yyyy-MM-dd").toJSDate() : null,
              ]}
              setValue={setDuration}
              sx={{ maxWidth: "100%" }}
              onBlurSameDate={false}
            />
            {(formik.errors.start_at || formik.errors.end_at) && isSubmit && (
              <Typography variant="body1" sx={{ fontSize: "0.9rem", margin: "0.5rem 0", color: "error.dark" }}>
                {formik.errors.start_at || formik.errors.end_at}
              </Typography>
            )}
            <Typography variant="body1" sx={{ fontSize: "1rem", margin: "1rem 0 0.5rem", fontWeight: 700 }}>
              Link
            </Typography>
            <TextField
              size="small"
              color="primary"
              variant="outlined"
              fullWidth
              sx={{ fontSize: "1rem", marginBottom: "1.25rem" }}
              placeholder="https://url.com"
              inputProps={{
                tabIndex: 2,
              }}
              {...formik.getFieldProps("link")}
              {...getFieldErrors("link", formik)}
            />
          </form>
        </DialogContent>
        <DialogActions sx={{ padding: "0.75rem 1.25rem" }}>
          <InlineContainer justifyContent="space-between" width="100%">
            <NeutralButton onClick={handleCloseModal} sx={{ marginRight: 2 }} disableElevation>
              Cancel
            </NeutralButton>
            <AffirmativeButton
              type="submit"
              form="ad-form"
              disabled={isSavingAd || isEditingAd}
              sx={{
                whiteSpace: "nowrap",
                width: { xs: isNew ? "100%" : 140, md: 140 },
              }}
              data-testid={TestId.PriceItem.Save}
              loading={isSavingAd || isEditingAd}
              onClick={() => {
                setIsSubmit(true)
              }}
            >
              Save
            </AffirmativeButton>
          </InlineContainer>
        </DialogActions>
      </MuiDialog>
    )
  },
)
