import Box from "@mui/material/Box"
import MuiDialog from "@mui/material/Dialog"
import DialogTitle from "@mui/material/DialogTitle"
import DialogContent from "@mui/material/DialogContent"
import DialogActions from "@mui/material/DialogActions"
import NiceModal, { useModal } from "@ebay/nice-modal-react"
import Typography from "@mui/material/Typography"
import InlineContainer from "@/components/InlineContainer"
import Button from "@mui/material/Button"
import { matchSorter } from "match-sorter"
import { useQuery } from "@tanstack/react-query"
import { useTheme, useMediaQuery } from "@mui/material"
import { fetchAllPriceItems } from "@/features/price-management/price-management-queries"
import IconButton from "@mui/material/IconButton"
import CloseIcon from "@mui/icons-material/CloseRounded"
import { styled } from "@mui/material"
import FormattedNumber from "@/components/FormattedNumber"
import RightArrowIcon from "@mui/icons-material/ChevronRightRounded"
import { useState } from "react"
import { getDollarsFromInt } from "@/utils/calculations"
import TextField from "@/components/TextField"
import PlusIcon from "@mui/icons-material/AddRounded"
import Checkbox from "@mui/material/Checkbox"
import { Query, DialogKeys, QuoteItem } from "@/types"
import useAppSelector from "@/hooks/useAppSelector"
import { selectQuoteItemIds, selectQuoteItems } from "@/features/quote-management/quote-management-selectors"
import InventoryQuantity from "@/features/price-management/components/InventoryQuantity"
import AffirmativeButton from "@/components/buttons/AffirmativeButton"
import { selectInvoiceItemIds, selectInvoiceItems } from "../invoice-management/invoice-management-selectors"

export const ItemList = styled(Box)(
  () => `
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;
  margin: 0.75rem auto;
`,
)

export const ItemCard = styled(InlineContainer, {
  shouldForwardProp: (prop) => prop !== "highlight",
})<{ highlight?: boolean; disabled?: boolean }>(
  ({ theme, highlight, disabled }) => `
  background-color: ${theme.palette.common.white};
  border-width: 1px;
  border-style: solid;
  border-color: ${highlight ? theme.palette.primary.main : theme.palette.common.white};
  border-radius: ${theme.shape.borderRadius}px;
  cursor: pointer;
  padding: ${theme.spacing(1)};
  width: 100%;

  &:hover {
    border-color: ${theme.palette.primary.main};
  }

  ${
    disabled
      ? `
      opacity: 0.3;
      cursor: not-allowed;
      border: none;
      `
      : ``
  }
`,
)

const PriceItemCard = ({ item, isSelected, handleSelectItem }) => (
  <ItemCard onClick={() => handleSelectItem(item)} highlight={isSelected} justifyContent="space-between">
    <InlineContainer>
      <Checkbox size="small" checked={isSelected} />
      <InlineContainer>
        <Typography variant="body1" sx={{ fontWeight: 500 }}>
          {item?.name}
        </Typography>
        <Typography variant="body1" sx={{ fontWeight: 500, margin: "0 0.5rem" }}>
          -
        </Typography>
        <Typography variant="body1" sx={{ color: "grey.600", fontWeight: 500 }}>
          <FormattedNumber prefix="$" value={getDollarsFromInt(item?.price)} />
        </Typography>
      </InlineContainer>
    </InlineContainer>
    {item?.is_track_inventory && (
      <InventoryQuantity
        minimum={parseFloat(item?.inventory_reorder_point ?? "0")}
        remaining={parseFloat(item?.inventory_on_hand ?? "0")}
        label="Available Qty: "
        sx={{ marginRight: "0.5rem" }}
      />
    )}
  </ItemCard>
)

export default NiceModal.create(({ mode }: { mode: "invoice" | "quote" }) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("md"))
  const modal = useModal(DialogKeys.INVOICE_QUOTE_PRICE_ITEM)
  const [search, setSearch] = useState("")
  const invoiceQuoteItems = useAppSelector(mode === "invoice" ? selectInvoiceItems : selectQuoteItems)
  const selectedInvoiceQuoteIds = useAppSelector(mode === "invoice" ? selectInvoiceItemIds : selectQuoteItemIds)

  const [selectedItems, setSelectedItems] = useState<QuoteItem[]>(
    invoiceQuoteItems?.filter((i) => selectedInvoiceQuoteIds?.includes(i?.id as string)) ?? [],
  )

  const { data: priceItems } = useQuery([Query.account.BUILDER_ALL_PRICE_ITEMS], () => fetchAllPriceItems(), {
    keepPreviousData: true,
  })

  const hasPriceItems = priceItems?.results?.items?.length > 0

  const searchedPriceItems: any = matchSorter(priceItems?.results?.items ?? [], search, {
    keys: ["name", "description", "price", "inventory_on_hand"],
  })

  const handleCloseModal = () => {
    modal.hide()
  }

  const handleSelectItem = (item) => {
    if (selectedItems.some((i) => i?.id === item?.id)) {
      return setSelectedItems(selectedItems.filter((i) => i?.id !== item?.id))
    }

    setSelectedItems([...selectedItems, item])
  }

  const formatSelectedPriceItem = (items) => {
    return items.map(({ id, ...item }) => ({
      ...item,
      account_priceitem_id: id,
    }))
  }

  const handleSetItemOptions = () => {
    const selectedPriceItems = formatSelectedPriceItem(selectedItems)

    NiceModal.show(DialogKeys.INVOICE_QUOTE_PRICE_ITEM_OPTIONS, {
      mode,
      selectedPriceItems,
    }).catch(() => modal.show({ mode }))
    handleCloseModal()
  }

  const handleAddNewPriceItem = () => {
    handleCloseModal()
    NiceModal.show(DialogKeys.PRICE_ITEM, { from_builder: true })
      .then((priceItem: any) => {
        NiceModal.show(DialogKeys.INVOICE_QUOTE_PRICE_ITEM_OPTIONS, {
          mode,
          selectedPriceItems: formatSelectedPriceItem([priceItem]),
        }).catch(() => modal.show({ mode }))
      })
      .catch(() => modal.show({ mode }).then(handleSelectItem))
  }

  const CreateNewButton = () => (
    <Box textAlign="center" padding="0 0.5rem 1.25rem">
      <Button
        sx={{ backgroundColor: "primary.dark" }}
        variant="contained"
        onClick={handleAddNewPriceItem}
        startIcon={<PlusIcon fontSize="small" />}
      >
        Create New
      </Button>
    </Box>
  )

  return (
    <MuiDialog
      open={modal.visible}
      maxWidth="sm"
      fullWidth
      fullScreen={isMobile}
      TransitionProps={{
        onExited: () => modal.remove(),
      }}
    >
      <DialogTitle sx={{ padding: "0.75rem 1.25rem" }}>
        <InlineContainer justifyContent="space-between">
          <Typography
            color="primary"
            variant="h6"
            sx={{
              paddingLeft: { xs: "30px", md: 0 },
              textAlign: { xs: "center", md: "left" },
              width: "100%",
            }}
          >
            Products
          </Typography>
          <IconButton
            aria-label="close"
            size="small"
            onClick={handleCloseModal}
            sx={{
              color: (theme) => theme.palette.grey[500],
              position: "relative",
              right: -6,
            }}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </InlineContainer>
      </DialogTitle>
      {priceItems?.results?.items?.length < 1 ? (
        <DialogContent sx={{ paddingTop: "3rem" }} dividers>
          <Typography
            variant="body1"
            sx={{
              fontWeight: 500,
              textAlign: "center",
              marginBottom: "2.5rem",
            }}
          >
            Looks like you don't have any products saved in the system. To create one, click the blue "+ Create New"
            button below.
          </Typography>
        </DialogContent>
      ) : (
        <DialogContent
          sx={{
            backgroundColor: "grey.200",
            padding: "1.25rem",
          }}
          dividers
        >
          <CreateNewButton />
          <Box sx={{ textAlign: "center", width: "100%" }}>
            <TextField
              name="price-item-search"
              placeholder="Search"
              sx={{
                backgroundColor: "white",
                borderRadius: "0.5rem",
                margin: "0 0 1rem",
                display: "flex",
              }}
              onChange={(event) => setSearch(event?.target?.value)}
            />
          </Box>
          {Boolean(search) ? null : (
            <>
              <Typography
                variant="body1"
                sx={{
                  fontWeight: 500,
                  margin: "0 auto",
                }}
              >
                Most Used
              </Typography>
              <ItemList>
                {priceItems?.results?.most_frequently_used_items?.map((item) => (
                  <PriceItemCard
                    key={`invoice-quote-frequently-used-price-item-${item?.id}`}
                    item={item}
                    isSelected={selectedItems.some((i) => i?.id === item?.id)}
                    handleSelectItem={handleSelectItem}
                  />
                ))}
              </ItemList>
            </>
          )}
          <Typography
            variant="body1"
            sx={{
              fontWeight: 500,
              margin: "1.25rem auto 0",
            }}
          >
            All Items
          </Typography>
          <ItemList>
            {searchedPriceItems
              ?.filter((i) => !selectedInvoiceQuoteIds?.includes(i?.account_priceitem_id))
              ?.map((item) => (
                <PriceItemCard
                  key={`invoice-quote-price-item-${item?.id}`}
                  item={item}
                  isSelected={selectedItems.some((i) => i?.id === item?.id)}
                  handleSelectItem={handleSelectItem}
                />
              ))}
          </ItemList>
        </DialogContent>
      )}
      <DialogActions sx={{ padding: "0.75rem 1.25rem", justifyContent: "center" }}>
        <AffirmativeButton
          startIcon={hasPriceItems ? null : <PlusIcon fontSize="small" />}
          endIcon={hasPriceItems ? <RightArrowIcon fontSize="small" /> : null}
          onClick={hasPriceItems ? handleSetItemOptions : handleAddNewPriceItem}
          disabled={selectedItems?.length < 1 && hasPriceItems}
          sx={{ width: { xs: "100%", md: 170 } }}
        >
          {hasPriceItems ? "Continue" : "Create New"}
        </AffirmativeButton>
      </DialogActions>
    </MuiDialog>
  )
})
