import { createAction, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { logoutThunk } from "@/features/auth/auth-queries"
import { PaletteMode } from "@mui/material"
import { RootState } from "@/config/store"
import { HYDRATE } from "next-redux-wrapper"

export type PartnerTheme = {
  id?: string
  primaryColor?: string
  secondaryColor?: string
  avatar_url?: string
  white_logo_url?: string
  fav_icon_url?: string
  name?: string
}

export interface LayoutState {
  loading?: boolean
  collapseNav?: boolean
  showMobileMenu?: boolean
  showSearch?: boolean
  searchQuery?: string
  colorMode?: PaletteMode | null
  theme?: PartnerTheme | null
  tableCollapseState?: any
}

const initialState: LayoutState = {
  loading: false,
  collapseNav: false,
  showMobileMenu: false,
  showSearch: false,
  searchQuery: "",
  colorMode: null,
  theme: null,
  tableCollapseState: {},
}

const hydrate = createAction<RootState>(HYDRATE)

export const layoutSlice = createSlice({
  name: "layout",
  initialState,
  reducers: {
    setColorMode: (state, action: PayloadAction<PaletteMode>) => {
      state["colorMode"] = action?.payload
    },
    toggleCollapsedNav: (state, action: PayloadAction<null | undefined | boolean>) => {
      state.collapseNav = action?.payload ?? !state?.collapseNav
    },
    toggleMobileMenu: (state, action: PayloadAction<null | undefined | boolean>) => {
      state.showMobileMenu = action?.payload ?? !state?.showMobileMenu
    },
    togglePageLoading: (state, action: PayloadAction<null | undefined | boolean>) => {
      state.loading = Boolean(action.payload)
    },
    toggleGlobalSearch: (state, action: PayloadAction<null | undefined | boolean>) => {
      state.showSearch = Boolean(action.payload ?? !state.showSearch)
    },
    setGlobalSearchQuery: (state, action: PayloadAction<string>) => {
      state.searchQuery = action.payload
    },
    setPartnerTheme: (state, action: PayloadAction<PartnerTheme>) => {
      state["theme"] = {
        id: action.payload.id,
        primaryColor: action.payload.primaryColor,
        secondaryColor: action.payload.secondaryColor,
        avatar_url: action.payload.avatar_url,
        white_logo_url: action.payload.white_logo_url,
        fav_icon_url: action.payload.fav_icon_url,
        name: action.payload.name,
      }
    },
    resetPartnerTheme: (state) => {
      state["theme"] = null
    },
    expandAllRows: (state) => {
      const prepState = Object.entries(state.tableCollapseState).map(([key]) => [key, true])
      const newState = Object.fromEntries(prepState)
      return {
        ...state,
        tableCollapseState: newState,
      }
    },
    collapseAllRows: (state) => {
      const prepState = Object.entries(state.tableCollapseState).map(([key]) => [key, false])
      const newState = Object.fromEntries(prepState)
      return {
        ...state,
        tableCollapseState: newState,
      }
    },
    toggleRow: (state, action) => {
      return {
        ...state,
        tableCollapseState: {
          ...state.tableCollapseState,
          [action.payload]: !state.tableCollapseState[action.payload],
        },
      }
    },
    registerRows: (state, action) => {
      return {
        ...state,
        tableCollapseState: {
          ...state.tableCollapseState,
          ...Object.fromEntries(action.payload.map((id) => [id, state.tableCollapseState?.[id] ?? false])),
        },
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(logoutThunk.fulfilled, (state) => ({
        ...initialState,
        // Note: Maintaining the theme values on logout prevents a flash
        // of the default theme before the login page loads when the user
        // is logging out of a partner themed experience.
        ...(Object.keys(state?.theme ?? {}).length > 0 ? { ...initialState, theme: state?.theme } : {}),
      }))
      .addCase(hydrate, (state, action) => {
        return {
          ...state,
          ...(action?.payload?.layout ?? {}),
        }
      })
  },
})

export const {
  toggleMobileMenu,
  toggleCollapsedNav,
  togglePageLoading,
  toggleGlobalSearch,
  setGlobalSearchQuery,
  setColorMode,
  setPartnerTheme,
  resetPartnerTheme,
  expandAllRows,
  collapseAllRows,
  toggleRow,
  registerRows,
} = layoutSlice.actions

export default layoutSlice.reducer
