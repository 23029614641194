import Dialog, { DialogContent, DialogActions } from "@/components/Dialog"
import NiceModal, { useModal } from "@ebay/nice-modal-react"
import Typography from "@mui/material/Typography"
import InlineContainer from "@/components/InlineContainer"
import { useMutation } from "@tanstack/react-query"
import getFieldErrors from "@/utils/getFieldErrors"
import { inviteMemberValidationSchema } from "@/features/account-management/account-management-validation"
import { useFormik } from "formik"
import { useTheme, useMediaQuery, Select, MenuItem } from "@mui/material"
import { inviteTeamMember } from "@/features/account-management/account-management-queries"
import TextField from "@/components/TextField"
import SystemFeedback from "@/components/SystemFeedback"
import AffirmativeButton from "@/components/buttons/AffirmativeButton"
import NeutralButton from "@/components/buttons/NeutralButton"
import useMessage from "@/hooks/useMessage"
import { useSnackbar } from "notistack"
import { AccountRoles, AccountRolesList } from "@/types"
import { selectAdminRole } from "@/features/auth/auth-selectors"
import useAppSelector from "@/hooks/useAppSelector"
import useCurrentRole from "@/hooks/useCurrentRole"

export default NiceModal.create(
  ({ isAccount = true, invite }: { isAccount?: boolean; invite?: (values: any) => void }) => {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down("md"))
    const isDesktop = !isMobile
    const modal = useModal()
    const { enqueueSnackbar } = useSnackbar()
    const [message, setMessage] = useMessage()
    const isFinliAdmin = useAppSelector(selectAdminRole)
    const { isOwner } = useCurrentRole()

    const isAccountOwnerOrAdmin = isOwner || isFinliAdmin

    const inviteMember = useMutation(inviteTeamMember, {
      onSuccess: async () => {
        enqueueSnackbar(`Invite sent to new team member`, {
          variant: "success",
        })
        modal.resolve()
        modal.hide()
      },
      onError: (error: any) => {
        setMessage({
          type: "error",
          message: error?.response?.data?.message,
        })
      },
    })

    const formik = useFormik({
      initialValues: {
        isPhone: 0,
        email_or_phone: "",
        role: isAccount ? AccountRoles.ACCOUNT_MEMBER : AccountRoles.PARTNERSHIP_MEMBER,
      },
      validationSchema: inviteMemberValidationSchema,
      onSubmit: async (values) => {
        const valuesToSend = {
          ...(values?.isPhone ? { cell_phone: values?.email_or_phone } : { email: values?.email_or_phone }),
          role: values?.role,
        }

        ;(invite || inviteMember.mutate)(valuesToSend)
      },
    })

    const handleCloseModal = () => {
      modal.reject()
      modal.hide()
    }

    return (
      <Dialog modal={modal} title="Invite Team Member">
        <DialogContent>
          {isAccount && (
            <ul style={{ marginTop: 0, paddingLeft: "1.5rem" }}>
              <li>
                <b>Admin:</b>&nbsp; Has full control over business settings, team management, banking details.
              </li>
              <li>
                <b>Team Member:</b>&nbsp; Can view business details, invite team members.
              </li>
            </ul>
          )}
          <form onSubmit={formik.handleSubmit} id="invite-member-form">
            <SystemFeedback {...message} />
            <Typography variant="body1" sx={{ fontWeight: 600 }}>
              Email or mobile phone number
            </Typography>
            <TextField
              size="small"
              color="primary"
              variant="outlined"
              fullWidth
              sx={{ fontSize: "1rem", marginTop: "0.25rem" }}
              {...formik.getFieldProps("email_or_phone")}
              {...getFieldErrors("email_or_phone", formik)}
              onChange={(e) => {
                const value = e.target.value

                // Checks if the value is an email
                if (value.match(/\@|\.|[a-zA-Z]/g)) {
                  formik.setFieldValue("isPhone", 0)
                } else {
                  formik.setFieldValue("isPhone", 1)
                }

                formik.setFieldValue("email_or_phone", value)
              }}
            />
            <Typography variant="body1" sx={{ fontWeight: 600, marginTop: "1rem" }}>
              Role
            </Typography>
            {isAccountOwnerOrAdmin ? (
              <Select
                sx={{ width: "100%", height: "2.5rem" }}
                onChange={(e) => {
                  formik.setFieldValue("role", e.target.value)
                }}
                value={formik.values.role}
              >
                {isAccount && <MenuItem value={AccountRoles.ACCOUNT_OWNER}>Admin</MenuItem>}
                {isAccount && <MenuItem value={AccountRoles.ACCOUNT_MEMBER}>Team Member</MenuItem>}
                {!isAccount && <MenuItem value={AccountRoles.PARTNERSHIP_OWNER}>Admin</MenuItem>}
                {!isAccount && <MenuItem value={AccountRoles.PARTNERSHIP_MEMBER}>Team Member</MenuItem>}
              </Select>
            ) : (
              <Typography>{AccountRolesList[formik.values.role]}</Typography>
            )}
          </form>
        </DialogContent>
        <DialogActions>
          <InlineContainer justifyContent="space-between" width="100%">
            {isDesktop && (
              <NeutralButton onClick={handleCloseModal} disableElevation>
                Cancel
              </NeutralButton>
            )}
            <AffirmativeButton type="submit" form="invite-member-form" fullWidth={!isDesktop}>
              Invite
            </AffirmativeButton>
          </InlineContainer>
        </DialogActions>
      </Dialog>
    )
  },
)
