import { DatePickerInput as DRP } from "@mantine/dates"
import { styled } from "@mui/material"
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown"
import Typography from "@mui/material/Typography"
import EventIcon from "@mui/icons-material/Event"
import CloseIcon from "@mui/icons-material/Close"
import { DateTime } from "luxon"
import { useTheme } from "@emotion/react"

const StyledDateRangePicker = styled(DRP, {
  shouldForwardProp: (prop) => prop !== "loading",
})(
  ({}) => `
  max-width: 360px;

  > div > button {
    border-radius: 0.625rem;
    padding: 8.5px 14px;
    font-family: Poppins;
    font-size: 1rem;

    &:hover {
      border-color: black;
    }
  }
`,
)

const DateRangePicker = ({ value, setValue, sx = {}, onBlurSameDate }) => {
  const theme = useTheme()

  return (
    <>
      <style jsx global>{`
        div.mantine-Popover-dropdown {
          z-index: 999999 !important;

          .mantine-DatePickerInput-calendarHeader {
            display: grid;
            grid-template-columns: 1fr max-content max-content;

            > button:nth-child(2) {
              padding: 0 0.1rem;

              &:hover {
                background-color: white;
              }
            }

            > button:nth-child(1),
            > button:nth-child(3) {
              border-radius: 50%;
              > svg {
                width: 60% !important;
                height: 60% !important;
              }
            }
          }

          .mantine-DatePickerInput-calendarHeaderLevel {
            order: -1;
            justify-self: flex-start;
            padding-inline: var(--mantine-spacing-md);
          }

          td > button {
            &:where([data-weekend]) {
              color: var(--mantine-color-text);
            }

            &:where([data-outside]) {
              display: none;
            }

            &:where([data-today][data-highlight-today]:not([data-selected], [data-in-range])) {
              border: 1px solid rgba(0, 0, 0, 0.6);
              border-radius: 50%;
            }

            &:where([data-in-range]) {
              background-color: ${theme.palette.primary.main}11;
            }

            &:where([data-selected]),
            &:where([data-first-in-range]),
            &:where([data-last-in-range]),
            &:hover {
              color: white !important;
              background-color: ${theme.palette.primary.main} !important;
            }

            &:where([data-first-in-range]) {
              border-start-start-radius: 50%;
              border-end-start-radius: 50%;
            }

            &:where([data-last-in-range]) {
              border-start-end-radius: 50%;
              border-end-end-radius: 50%;
            }
          }
        }
      `}</style>
      <StyledDateRangePicker
        clearable
        monthLabelFormat={(month) => {
          return (
            <Typography variant="body1" sx={{ fontWeight: "bold", marginBottom: "0.25rem" }}>
              {DateTime.fromJSDate(month).toFormat("MMMM yyyy")}
              <ArrowDropDownIcon
                sx={{
                  fontSize: "1.5rem",
                  position: "relative",
                  top: "0.35rem",
                  marginLeft: "0.5rem",
                  color: "#757575",
                }}
              />
            </Typography>
          )
        }}
        type="range"
        placeholder="MM/DD/YYYY (to MM/DD/YYYY)"
        value={value}
        onChange={(v) => {
          if (value?.[0] && !v?.[0] && !v?.[1]) {
            // single selection
            setValue([value?.[0], onBlurSameDate ? value?.[0] : null])
          } else {
            setValue(v)
          }
        }}
        rightSection={
          value?.[0] || value?.[1] ? (
            <CloseIcon
              sx={{
                fontSize: "1.25rem",
                cursor: "pointer",
              }}
              onClick={() => setValue([])}
            />
          ) : (
            <EventIcon
              sx={{
                fontSize: "1.25rem",
                cursor: "pointer",
                color: "#757575",
              }}
            />
          )
        }
        pointer
        rightSectionWidth={40}
        rightSectionPointerEvents={value?.[0] || value?.[1] ? undefined : "none"}
        allowSingleDateInRange
        valueFormatter={({ date }) => {
          // @ts-ignore
          const [start, end] = date
          const startIso = DateTime.fromJSDate(start).toFormat("MM/dd/yyyy")
          const endIso = DateTime.fromJSDate(end).toFormat("MM/dd/yyyy")

          if (!start) {
            return ""
          } else if (startIso === endIso || !end) {
            return startIso
          } else {
            return `${startIso} to ${endIso}`
          }
        }}
        firstDayOfWeek={0}
        highlightToday
        style={sx}
      />
    </>
  )
}

export default DateRangePicker
