import axios, { AxiosInstance } from "axios"
import stringifyQueryParams from "@/utils/stringifyQueryParams"
import { GetServerSidePropsContext } from "next"
import { PaymentCreateMethod } from "@/types"

let apiClient: AxiosInstance

const PaymentMethodSubRoutes = {
  [PaymentCreateMethod.OFFLINE]: {
    route: "offline",
  },
  [PaymentCreateMethod.ONETIME]: {
    route: "onetime",
  },
  [PaymentCreateMethod.WALLET]: {
    route: "wallet",
  },
  [PaymentCreateMethod.APPLE_PAY]: {
    route: "onetime/apple-pay",
  },
}

export const clientBaseUrl = `${process.env.ENV_NAME === "local" ? "http://" : "https://portal."}${process.env.DOMAIN}`

export const initApi = (req?: GetServerSidePropsContext["req"] | null) => {
  // Make sure to create a new client for every server-side request so that data
  // isn't shared between connections
  if (typeof window === "undefined") {
    const api = axios.create({
      baseURL: process.env.API_URL + "/v1",
      withCredentials: true,
    })

    if (!req) {
      throw new Error("Request unavailable")
    }

    const token = getSessionToken(req)
    api.defaults.headers.common["Authorization"] = `Bearer ${token}`
    api.defaults.headers.common["origin"] = req.headers.host as string

    return api
  }

  // Reuse client on the client-side
  if (!apiClient) {
    apiClient = axios.create({
      baseURL: process.env.API_URL + "/v1",
      withCredentials: true,
    })
  }

  return apiClient
}

let TOKEN_KEY = "token"

if (process.env.ENV_NAME === "staging") {
  TOKEN_KEY += "_staging"
} else if (process.env.ENV_NAME === "dev") {
  TOKEN_KEY += "_dev"
}

export const getSessionToken = (req: GetServerSidePropsContext["req"]) => {
  return req.cookies[TOKEN_KEY] as string
}

export const routes = {
  user: {
    context: {
      switch: "/user/context/switch",
    },
    base: "/user",
    upload: "/user/file-upload",
    verify: "/user/otc/verify",
    resend: "/user/otc",
    login: "/user/login",
    logout: "/user/logout",
    signup: {
      withCredentials: "/user/account/signUp",
      withGoogle: "/user/account/signUp",
    },
    otc: "/user/otc",
    reset: {
      passwordNotification: "/user/password/reset/notification",
      password: "/user/password/reset",
    },
    accounts: "/user/accounts",
    account: {
      switch: `/user/account/switch`,
      accept: (id: string) => `/user/account/role/${id}/accept`,
    },
    otp: {
      enroll: "/user/otp/enroll",
      unenroll: "/user/otp/unenroll",
      verify: "/user/otp/verify",
    },
    associateBusiness: (customerId: string) => `/user/account/customer/${customerId}`,
    googleConnect: "/user/google/connect",
    googleDisconnect: "/user/google/disconnect",
    payments: (filters?: any) => `/user/payments${stringifyQueryParams(filters)}`,
    paymentExport: (filters?: any) => `/user/payments/export${stringifyQueryParams(filters)}`,
    invoices: (filters?: any) => `/user/invoices${stringifyQueryParams(filters)}`,
    quotes: (filters?: any) => `/user/quotes${stringifyQueryParams(filters)}`,
    partnership: {
      switch: `/user/partnership/switch`,
    },
    deletePaymentSeries: (seriesId: string) => `/payment/series/${seriesId}/cancel`,
    invite: (roleId) => `/user/role/${roleId}`,
  },
  account: {
    base: "/account",
    create: "/account",
    upload: "/user/file-upload",
    users: "/account/users",
    paymentQrCode: `/account/payByQr`,
    subdomain: (subdomain: string) => `/account/validateSubdomain?subdomain=${subdomain}`,
    analytics: (filters?: any) => `/account/analytics${stringifyQueryParams(filters)}`,
    ads: () => `/account/ads`,
    activities: (filters?: any) => `/account/activities${stringifyQueryParams(filters)}`,
    settlementsExport: () => `/account/settlements/export`,
    payments: (filters?: any) => `/account/payments${stringifyQueryParams(filters)}`,
    integration: {
      create: "/account/integration",
    },
    pbl: (subdomain) => `/account/pbl/${subdomain}`,
    subscription: {
      create: "/account/subscription",
    },
    verifyInquiry: "/account/persona/verify-inquiry",
  },
  wallet: {
    base: (walletId: string) => `/wallet/${walletId}`,
    walletInstrument: (walletId: string) => `/wallet/${walletId}/instrument`,
    instrument: (walletInstrumentId: string) => `/wallet/instrument/${walletInstrumentId}`,
    instrumentVerify: (walletInstrumentId: string) => `/wallet/instrument/${walletInstrumentId}/verify`,
    plaidLinkToken: (walletId: string, isDisableMicroDeposit: boolean) =>
      `/wallet/${walletId}/plaid-link-token?isDisableMicroDeposit=${isDisableMicroDeposit}`,
    futurePayments: (walletInstrumentId: string) => `/wallet/instrument/${walletInstrumentId}/future-payments`,
  },
  role: {
    create: "/account/role",
    update: (id: string) => `/account/role/${id}`,
    delete: (id: string) => `/account/role/${id}`,
    resendNotification: (id: string) => `/account/role/${id}/notification/resend`,
  },
  contact: {
    search: (searchQuery: string) => `/account/pbl/contact/search?value=${searchQuery}`,
    notify: `/account/pbl/send`,
  },
  customer: {
    create: "/account/customer",
    id: (customerId: string) => `/account/customer/${customerId}`,
    category: (categoryId: string) => `/account/customer/category/${categoryId}`,
    categories: (customerId: string) => `/account/customer/${customerId}/categories`,
    list: (filters) => `/account/customers${stringifyQueryParams(filters)}`,
    all: "/account/customers/all",
    search: (filters) => `/account/customers/all${stringifyQueryParams(filters)}`,
    import: "/account/customer/import",
    export: "/account/customers/export",
    invoices: (customerId: string, filters?: any) =>
      `/account/invoices${stringifyQueryParams({
        account_customer_ids: [customerId],
        ...filters,
      })}`,
    invoiceExport: (customerId: string, filters?: any) =>
      `/account/invoices/export${stringifyQueryParams({
        ...filters,
        account_customer_ids: [customerId],
      })}`,
    quotes: (customerId: string, filters?: any) =>
      `/account/quote${stringifyQueryParams({
        account_customer_ids: [customerId],
        ...filters,
      })}`,
    quoteExport: (customerId: string, filters?: any) =>
      `/account/quote/export${stringifyQueryParams({
        ...filters,
        account_customer_ids: [customerId],
      })}`,
    switchAutopay: (customerId: string) => `/user/account/customer/${customerId}`,
    outstandingInvoices: (customerId: string, instrumentId: string) =>
      `/user/account/customer/${customerId}/outstanding-invoices/${instrumentId}`,
  },
  tags: {
    all: "/account/tags",
    create: "/account/customers/tags",
    delete: (tagId: string) => `/account/customer/tag/${tagId}`,
  },
  price: {
    allTaxes: "/account/taxes/all",
    taxes: (filters?: any) => `/account/taxes${stringifyQueryParams(filters)}`,
    tax: "/account/tax",
    allModifiers: "/account/priceModifiers/all",
    modifiers: (filters?: any) => `/account/priceModifiers${stringifyQueryParams(filters)}`,
    modifier: "/account/priceModifier",
    allItems: "/account/priceItems/all",
    itemsImport: "/account/priceItems/import",
    items: (filters?: any) => `/account/priceItems${stringifyQueryParams(filters)}`,
    item: "/account/priceItem",
    export: {
      taxes: (filters?: any) => `/account/taxes/export${stringifyQueryParams(filters)}`,
      modifiers: (filters?: any) => `/account/priceModifiers/export${stringifyQueryParams(filters)}`,
      item: (filters?: any) => `account/priceItems/export${stringifyQueryParams(filters)}`,
    },
    delete: {
      tax: (id: string) => `/account/tax/${id}`,
      modifier: (id: string) => `/account/priceModifier/${id}`,
      item: (id: string) => `/account/priceItem/${id}`,
    },
  },
  attachment: {
    create: (customerId: string) => `/account/customer/${customerId}/attachment`,
    delete: (attachmentId: string) => `/account/customer/attachment/${attachmentId}`,
  },
  quote: {
    list: (filters?: any) => `/account/quote${stringifyQueryParams(filters)}`,
    id: (quoteId: string) => `/account/quote/${quoteId}`,
    action: (quoteId: string) => `/account/quote/${quoteId}/action`,
    export: (filters?: any) => `/account/quote/export${stringifyQueryParams(filters)}`,
    save: "/account/quote/save",
    batch: "/account/quote-batch",
    disapprove: (quoteId: string) => `/account/quote/${quoteId}/disapprove`,
    pdf: (quoteId: string) => `/account/quote/${quoteId}/pdf`,
    abl: (quoteId: string) => `/quote/${quoteId}/abl`,
    code: (quoteId: string) => `/quote/${quoteId}/magic-code`,
    login: (quoteId: string) => `/quote/${quoteId}/login`,
    approve: (quoteId: string) => `/quote/${quoteId}/approve`,
    reject: (quoteId: string) => `/quote/${quoteId}/reject`,
  },
  invoice: {
    list: (filters?: any) => `/account/invoices${stringifyQueryParams(filters)}`,
    save: "/account/invoice/save",
    batch: "/account/invoice-batch",
    export: (filters?: any) => `/account/invoices/export${stringifyQueryParams(filters)}`,
    status: (invoiceId: string) => `/account/invoice/${invoiceId}/status`,
    action: (invoiceId: string) => `/account/invoice/${invoiceId}/action`,
    pdf: (invoiceId: string) => `/account/invoice/${invoiceId}/pdf`,
    seriesAction: (seriesId: string) => `/account/series/${seriesId}/action`,
    id: (invoiceId: string) => `/account/invoice/${invoiceId}`,
    pil: (invoiceId: string) => `/invoice/${invoiceId}/pil`,
    bdpil: (invoiceId: string) => `/invoice/${invoiceId}/pil/bd`,
    login: (invoiceId: string) => `/invoice/${invoiceId}/login`,
    code: (invoiceId: string) => `/invoice/${invoiceId}/magic-code`,
    payment: (invoiceId: string) => `/invoice/${invoiceId}/payment`,

    // approval flow
    approve: (invoiceId: string) => `/invoice/${invoiceId}/approve`,
    disapprove: (invoiceId: string) => `/invoice/${invoiceId}/disapprove`,
  },
  payments: {
    list: (filters?: any) => `/account/payments${stringifyQueryParams(filters)}`,
    payment: (paymentId: string) => `/payment/${paymentId}`,
    accountPayment: (paymentId: string) => `/payment/${paymentId}/account`,
    bdpayment: (paymentId: string) => `/payment/${paymentId}/bd`,
    export: (filters?: any) => `/account/payments/export${stringifyQueryParams(filters)}`,
    refund: (paymentId: string) => `/payment/${paymentId}/refund`,
    sendReceipt: (paymentId?: string) => `/payment/${paymentId}/receipt/send`,
    downloadReceipt: (paymentId?: string) => `/payment/${paymentId}/pdf`,
    create: (method: PaymentCreateMethod) => `/payment/${PaymentMethodSubRoutes[method].route}`,
    bdcreate: (method: PaymentCreateMethod) => `/payment/${PaymentMethodSubRoutes[method].route}/bd`,
    applePaySession: "/payment/apple-pay/session",
  },
  search: (value: string) => `/account/search?value=${value}`,
  admin: {
    resetProductUpdates: `/admin/users/reset-product-updates`,
    removeUserFromMerchant: (merchantId: string, userId: string) => `/admin/merchant/${merchantId}/${userId}`,
    merchant: {
      list: (filters?: any) => `/admin/merchants${stringifyQueryParams(filters)}`,
      export: (filters?: any) => `/admin/merchants/export${stringifyQueryParams(filters)}`,
      id: (merchantId?: string) => `/admin/merchant/${merchantId}`,
    },
    invoices: {
      list: (filters?: any) => `/admin/invoices${stringifyQueryParams(filters)}`,
      export: (filters?: any) => `/admin/invoices/export${stringifyQueryParams(filters)}`,
      id: (invoiceId?: string) => `/admin/invoices/${invoiceId}`,
    },
    quotes: {
      list: (filters?: any) => `/admin/quotes${stringifyQueryParams(filters)}`,
      export: (filters?: any) => `/admin/quotes/export${stringifyQueryParams(filters)}`,
      id: (quoteId?: string) => `/admin/quotes/${quoteId}`,
    },
    payments: {
      cardReturn: `/admin/payments/cc-return`,
      cardReturnReversal: (paymentId) => `/admin/payment/${paymentId}/unreturn`,
      payout: `/admin/payments/payout`,
      list: (filters?: any) => `/admin/payments${stringifyQueryParams(filters)}`,
      export: (filters?: any) => `/admin/payments/export${stringifyQueryParams(filters)}`,
      refund: (paymentId: string) => `/admin/payment/${paymentId}/refund`,
    },
    users: {
      list: (filters?: any) => `/admin/users${stringifyQueryParams(filters)}`,
      export: (filters?: any) => `/admin/users/export${stringifyQueryParams(filters)}`,
      user: (userId: string) => `/admin/user/${userId}`,
      invoices: (userId: string) => `/admin/user/${userId}/invoices`,
      merge: `/admin/users/merge`,
      passwordReset: (userId: string) => `/admin/user/${userId}/password-reset`,
    },
    partnership: {
      public: (partnerId: string) => `/partnership/${partnerId}/public`,
      edit: (partnerId: string) => `/admin/partnerships/${partnerId}`,
      list: (filters?: any) => `/admin/partnerships${stringifyQueryParams(filters)}`,
      partner: (partnerId: string) => `/admin/partnerships/${partnerId}`,
      invoices: (partnerId: string) => `/admin/partnerships/${partnerId}/invoices`,
      ads: (partnerId: string) => `/admin/partnerships/${partnerId}/ads`,
      addMerchant: (partnerId: string) => `/admin/partnerships/${partnerId}/addMerchant`,
      merchant: (partnerId: string) => `/admin/partnerships/${partnerId}/merchant`,
      accounts: (partnerId: string) => `/admin/partnerships/${partnerId}/accounts`,
      export: (filters?: any) => `/admin/partnerships/export${stringifyQueryParams(filters)}`,
      exportInvoices: (filters?: any) => `/admin/partnerships/exportInvoices${stringifyQueryParams(filters)}`,
      ad: {
        create: (partnerId: string) => `/admin/partnerships/${partnerId}/ad`,
        update: (partnershipAdId: string) => `/admin/partnerships/ad/${partnershipAdId}`,
      },

      // roles
      users: (partnerId: string) => `/admin/partnerships/${partnerId}/users`,
      role: {
        create: (partnerId: string) => `/admin/partnerships/${partnerId}/role`,
        edit: (partnerId: string, roleId: string) => `/admin/partnerships/${partnerId}/role/${roleId}`,
        resendNotification: (partnerId, id: string) =>
          `/admin/partnerships/${partnerId}/role/${id}/notification/resend`,
      },
      account: {
        create: (partnerId: string) => `/admin/partnerships/${partnerId}/account`,
        update: (partnerId: string, accountId: string) => `/admin/partnerships/${partnerId}/account/${accountId}`,
      },
    },
    events: {
      base: (filters?: any) => `/admin/events${stringifyQueryParams(filters)}`,
    },
  },
  partnership: {
    base: "/partnership",
    users: "/partnership/users",
    analytics: (filters?: any) => `/partnership/analytics${stringifyQueryParams(filters)}`,
    receivables: (filters?: any) => `/partnership/receivables${stringifyQueryParams(filters)}`,
    business: (merchantId: string) => `/partnership/account/${merchantId}`,
    businesses: (filters?: any) => `/partnership/accounts${stringifyQueryParams(filters)}`,
    businessReceivables: (merchantId: string, filters?: any) =>
      `/partnership/account/${merchantId}/receivables${stringifyQueryParams(filters)}`,
    team: (teamId?: string) => `/partnership/team${teamId ? `/${teamId}` : ""}`,
    teams: (filters?: any) => `/partnership/teams${stringifyQueryParams(filters)}`,
    teamRoles: (teamId: string) => `/partnership/team/${teamId}/roles`,
    teamAccounts: (teamId: string) => `/partnership/team/${teamId}/accounts`,
    assignRoles: `/partnership/teams/roles`,
    assignAccounts: `/partnership/teams/accounts`,
    deleteTeamAccount: (teamId: string, businessId: string) => `/partnership/team/${teamId}/account/${businessId}`,
    accountTeams: (accountId: string) => `/partnership/account/${accountId}/teams`,
    roles: (filters?: any) => `/partnership/roles/${stringifyQueryParams(filters)}`,
    role: (memberId?: string) => `/partnership/role${memberId ? `/${memberId}` : ""}`,
    roleTeams: (memberId: string) => `/partnership/role/${memberId}/teams`,
    resendRoleNotification: (memberId: string) => `/partnership/role/${memberId}/notification/resend`,
    deleteRoleTeam: (memberId: string, teamId: string) => `/partnership/role/${memberId}/team/${teamId}`,
    businessInvite: `/partnership/account/invite`,
    deleteBusinessInvite: (accountId: string) => `/partnership/account/${accountId}/invite`,
    resendBusinessInvite: (accountId: string) => `/partnership/account/${accountId}/invite/resend`,
  },
}

export default initApi
