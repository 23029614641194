import { useCallback } from "react"
import Dropzone from "react-dropzone"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import { styled } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import UploadIcon from "@mui/icons-material/UploadFileRounded"

const Container = styled(Box)(
  ({ theme }) => `
  color: ${theme.palette.grey[400]};
	display: flex;
	flex-direction: column;
  justify-content: center;
  align-items: center;
	gap: 1rem;
  width: 100%;
	
	${theme.breakpoints.up("md")} {
		justify-content: flex-start;
	}
`,
)

const ImageUploader = ({
  values,
  onChange,
  size,
}: {
  values?: string | File | null
  onChange: (file: File | null) => void
  size?: [number, number]
}) => {
  const onDrop = useCallback(async (acceptedFiles) => {
    const file = acceptedFiles[0]
    onChange(file)

    const data = new FormData()
    data.append("image", file, file.name)
  }, [])

  const hasImage = !!values

  return (
    <Dropzone
      onDrop={onDrop}
      accept={{
        "image/png": [".png"],
        "image/jpeg": [".jpg", ".jpeg"],
      }}
      noClick={true}
      multiple={false}
    >
      {({ open, getRootProps, isDragActive }) => (
        <Container
          {...getRootProps()}
          onClick={(event) => {
            event.stopPropagation()
          }}
        >
          <Box
            onClick={open}
            sx={{
              borderRadius: "0",
              overflow: "hidden",
              width: size?.[0] || "100%",
              height: size?.[1] || 120,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              border: hasImage ? "none" : "2px dashed",
              borderColor: "grey.300",
            }}
          >
            {!hasImage ? (
              <Box
                width={size?.[0] || "100%"}
                height={size?.[1] || 120}
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{
                  cursor: "pointer",
                }}
              >
                {isDragActive ? (
                  <UploadIcon fontSize={"large"} sx={{ color: "primary.main" }} />
                ) : (
                  <Box justifyContent="center" display="flex" flexDirection="column" alignItems="center">
                    <UploadIcon fontSize={"large"} sx={{ color: "primary.main", marginBottom: "0.75rem" }} />
                    <Typography variant="body2" sx={{ color: "black", marginBottom: "0.25rem" }}>
                      Drop your .png file here, or&nbsp;
                      <Typography variant="body2" sx={{ color: "primary.main" }} display="inline">
                        browse your files.
                      </Typography>
                    </Typography>
                    {size && (
                      <Typography variant="body2" sx={{ color: "#758699" }}>
                        {size[0]} x {size[1]} px
                      </Typography>
                    )}
                  </Box>
                )}
              </Box>
            ) : (
              <Box sx={{ position: "relative" }}>
                <Box
                  sx={{
                    background: `url(${typeof values === "string" ? values : URL.createObjectURL(values)})`,
                    backgroundColor: "common.white",
                    backgroundSize: "70%",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    width: size?.[0] || "100%",
                    height: size?.[1] || 120,
                  }}
                />
                <CloseIcon
                  fontSize="small"
                  sx={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                    color: "black",
                    cursor: "pointer",
                  }}
                  onClick={(e) => {
                    onChange(null)
                    e.stopPropagation()
                  }}
                />
              </Box>
            )}
          </Box>
        </Container>
      )}
    </Dropzone>
  )
}

export default ImageUploader
