////////////////////////////////////////////////////////////////////////////////
export enum EventObjectType {
  INVOICE = "invoice",
  PAYMENT = "payment",
  CUSTOMER = "customer",
  USER = "user",
  ACCOUNT = "account",
}

export enum EventLog {
  // DB Triggers - our preference, if its a create delete or simple update in sql we can manage here
  INVOICE_CREATED = "invoice_created",
  // Questions
  // Do we want to limit scope to after issued? Definitely the most scale-scary
  INVOICE_UPDATED = "invoice_updated",
  INVOICE_SCHEDULED = "invoice_scheduled",
  INVOICE_ISSUED = "invoice_issued",
  INVOICE_CANCELED = "invoice_canceled",
  INVOICE_COMPLETED = "invoice_completed",
  INVOICE_DELETED = "invoice_deleted",
  INVOICE_SENT = "invoice_sent",
  INVOICE_REMINDER_SENT = "invoice_reminder_sent",

  QUOTE_CREATED = "quote_created",
  QUOTE_UPDATED = "quote_updated",
  QUOTE_SENT = "quote_sent",
  QUOTE_APPROVED = "quote_approved",
  QUOTE_INVOICED = "quote_invoiced",
  QUOTE_CANCELED = "quote_canceled",
  QUOTE_REJECTED = "quote_rejected",
  QUOTE_DELETED = "quote_deleted",

  CUSTOMER_CREATED = "customer_created",
  CUSTOMER_DELETED = "customer_deleted",
  // Questions
  // Do we need to catch all updates? For now just those hitting the patch controller, but might be missing
  CUSTOMER_UPDATED = "customer_updated",

  // In code triggers - for more complex updates and/or edge cases
  PAYMENT_CREATED = "payment_created",
  PAYMENT_DELETED = "payment_deleted",
  PAYMENT_UPDATED = "payment_updated",
  PAYMENT_FAILED = "payment_failed",
  PAYMENT_SUCCEEDED = "payment_succeeded",
  PAYMENT_REFUND_INITIATED = "payment_refund_initiated",
  PAYMENT_REFUND_IN_PROCESSING = "payment_refund_in_processing",
  PAYMENT_REFUND_OUT_PROCESSING = "payment_refund_out_processing",
  PAYMENT_REFUNDED = "payment_refunded",
  PAYMENT_REFUND_FAILED = "payment_refund_failed",
  PAYMENT_RETURNED = "payment_returned",

  INVOICECUSTOMER_VIEWED = "invoicecustomer_viewed",

  QUOTECUSTOMER_VIEWED = "quotecustomer_viewed",

  PRICEITEM_INVENTORY_RESTOCK = "priceitem_inventory_restock",
  PRICEITEM_INVENTORY_UPDATED = "priceitem_inventory_updated",
  PRICEITEM_CREATED = "priceitem_created",

  USER_LOGIN = "user_login",

  ROLE_CREATED = "role_created",
  ROLE_UPDATED = "role_updated",
  ROLE_ACCEPTED = "role_accepted",
  ROLE_DELETED = "role_deleted",
}

export enum UserInvoiceRelation {
  CUSTOMER = "CUSTOMER",
  ANONYMOUS = "ANONYMOUS",
  NON_CUSTOMER = "NON_CUSTOMER",
}

export enum TransactionFeePayerEnum {
  USER = "USER",
  ACCOUNT = "ACCOUNT",
}

export enum SaasSeriesEnum {
  MONTHLY = "MONTHLY",
  ANNUALLY = "ANNUALLY",
}

export enum PaymentSchedule {
  NOW = "NOW",
  DUE_DATE = "DUE_DATE",
  AUTOPAY = "AUTOPAY",
}

export enum BankAccountType {
  PERSONAL = "PERSONAL",
  BUSINESS = "BUSINESS",
}

export enum BankAccountSubtype {
  CHECKING = "checking",
  SAVING = "savings",
}

export enum PaymentBrand {
  ACH = "ACH",
  OFFLINE = "OFFLINE",
  VISA = "VISA",
  MASTERCARD = "MASTERCARD",
  DISCOVER = "DISCOVER",
  AMEX = "AMEX",
  DINERS_CLUB = "DINERS_CLUB",
  JCB = "JCB",
  UNION_PAY = "UNION_PAY",
  UNKNOWN = "UNKNOWN",
  VENMO = "VENMO",
  ZELLE = "ZELLE",
  PAYPAL = "PAYPAL",
  CASH = "CASH",
  CHECK = "CHECK",
  OTHER = "OTHER",
  BANK_ACCOUNT = "BANK_ACCOUNT",
  APPLE_PAY = "APPLE_PAY",
}

export enum HttpStatus {
  SUCCESS = 200,
  CREATED = 201,
  SUCCESS_NO_CONTENT = 204,
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  UNPROCESSABLE = 422,
  SERVER_ERROR = 500,
}

export enum AccountRoles {
  NONE = "NONE",
  ACCOUNT_OWNER = "ACCOUNT_OWNER",
  ACCOUNT_OWNER_PENDING = "ACCOUNT_OWNER_PENDING",
  ACCOUNT_MEMBER = "ACCOUNT_MEMBER",
  ACCOUNT_MEMBER_PENDING = "ACCOUNT_MEMBER_PENDING",
  PARTNERSHIP_OWNER = "PARTNERSHIP_OWNER",
  PARTNERSHIP_OWNER_PENDING = "PARTNERSHIP_OWNER_PENDING",
  PARTNERSHIP_MEMBER = "PARTNERSHIP_MEMBER",
  PARTNERSHIP_MEMBER_PENDING = "PARTNERSHIP_MEMBER_PENDING",
}

export enum AccountStatuses {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
  SUSPENDED = "SUSPENDED",
}

export enum UserStatuses {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
  PENDING = "PENDING",
}

export const AccountRolesList = {
  [AccountRoles.ACCOUNT_OWNER]: "Admin",
  [AccountRoles.ACCOUNT_OWNER_PENDING]: "Admin (Invite Sent)",
  [AccountRoles.ACCOUNT_MEMBER]: "Team Member",
  [AccountRoles.ACCOUNT_MEMBER_PENDING]: "Team Member (Invite Sent)",
  [AccountRoles.NONE]: "New User",
  [AccountRoles.PARTNERSHIP_OWNER]: "Admin",
  [AccountRoles.PARTNERSHIP_OWNER_PENDING]: "Admin (Invite Sent)",
  [AccountRoles.PARTNERSHIP_MEMBER]: "Team Member",
  [AccountRoles.PARTNERSHIP_MEMBER_PENDING]: "Member (Invite Sent)",
}

export enum BusinessRelationshipEnum {
  OWNER = "OWNER",
  DECISION_MAKER = "DECISION_MAKER",
  STAFF = "STAFF",
}

export enum BusinessLinkType {
  WEBSITE = "website",
  YELP = "yelp",
  TWITTER = "twitter",
  INSTAGRAM = "instagram",
  FACEBOOK = "facebook",
  GOOGLE_PAGES = "google-pages",
  LINKEDIN = "linkedin",
  TIKTOK = "tiktok",
  OTHER = "other",
}

export const businessRelationshipList = [
  {
    title: "Primary Owner",
    value: BusinessRelationshipEnum.OWNER,
  },
  {
    title: "Authorized Decision-Maker",
    value: BusinessRelationshipEnum.DECISION_MAKER,
  },
  {
    title: "Staff/Personnel",
    value: BusinessRelationshipEnum.STAFF,
  },
]

export enum BusinessStructureEnum {
  FREELANCE = "FREELANCE",
  SOLE_PROPRIETOR = "SOLE_PROPRIETOR",
  PARTNERSHIP = "PARTNERSHIP",
  LLC = "LLC",
  CORPORATION = "CORPORATION",
  NON_PROFIT = "NON_PROFIT",
}

export const businessStructuresList = [
  {
    title: "Freelance",
    value: BusinessStructureEnum.FREELANCE,
  },
  {
    title: "Sole Proprietor",
    value: BusinessStructureEnum.SOLE_PROPRIETOR,
  },
  {
    title: "Partnership",
    value: BusinessStructureEnum.PARTNERSHIP,
  },
  {
    title: "Limited Liability Company (LLC)",
    value: BusinessStructureEnum.LLC,
  },
  {
    title: "Corporation (S-Corp or C-Corp)",
    value: BusinessStructureEnum.CORPORATION,
  },
  {
    title: "Nonprofit or not-for-profit",
    value: BusinessStructureEnum.NON_PROFIT,
  },
]

export enum BusinessCustomerTypeEnum {
  B2B = "B2B",
  B2C = "B2C",
  B2BC = "B2BC",
}

export const customerTypeList = [
  {
    title: "Businesses",
    value: BusinessCustomerTypeEnum.B2B,
  },
  {
    title: "Individuals",
    value: BusinessCustomerTypeEnum.B2C,
  },
  {
    title: "Both",
    value: BusinessCustomerTypeEnum.B2BC,
  },
]

export enum DialogKeys {
  CONFIRM = "confirmation-dialog",
  INVOICE_CUSTOMER_OPTIONS = "invoice_customer_options",
  ACCEPT_INVOICE_CHANGES = "accept_invoice_changes",
  INVOICE_QUOTE_CUSTOMER = "invoice-quote-customer",
  INVOICE_QUOTE_PRICE_ITEM = "invoice-quote-price-item",
  INVOICE_QUOTE_PRICE_ITEM_OPTIONS = "invoice-quote-price-item-options",
  INVOICE_QUOTE_PRICE_MODIFIER = "invoice-quote-price-modifier",
  INVOICE_QUOTE_TAX = "invoice-quote-tax",
  INVOICE_QUOTE_PREVIEW = "invoice-quote-preview",
  INVOICE_SYNC_SAVE_CONFIRMATION = "invoice-sync-save-confirmation",
  PRICE_ITEM = "price-item",
  PRICE_MODIFIER = "price-modifier",
  TAX = "tax",
  PRICE_EDIT_DELETE = "price-edit-delete",
  CUSTOMER = "customer",
  CUSTOMER_CATEGORY = "customer-category",
  CUSTOMER_BULK_UPLOAD = "customer-bulk-upload",
  PRICE_ITEM_BULK_UPLOAD = "price-item-bulk-upload",
  CUSTOMER_CONFIRM_STATUS = "customer-confirm-status",
  CUSTOMER_BULK_TAGS = "customer-bulk-tags",
  CUSTOMER_ACTIVATE = "customer-activate",
  CUSTOMER_DEACTIVATE = "customer-deactivate",
  MAKE_QUOTE_ITEM_RECURRING = "make-quote-item-recurring",
  INVITE_MEMBER = "invite-member",
  SEND_INVOICE_CONFIRMATION = "send-invoice-confirmation",
  SHARE_INVOICE_CONFIRMATION = "share-invoice-confirmation",
  SEND_INVOICE_APPROVAL = "send-invoice-approval",
  SEND_UPDATED_INVOICE_CONFIRMATION = "send-updated-invoice-confirmation",
  LEAVE_INVOICE_CONFIRMATION = "leave-invoice-confirmation",
  CANCEL_INVOICE_CONFIRMATION = "cancel-invoice-confirmation",
  SKIP_INVOICE_CONFIRMATION = "skip-invoice-confirmation",
  PAUSE_INVOICE_CONFIRMATION = "pause-invoice-confirmation",
  DELETE_INVOICE_CONFIRMATION = "delete-invoice-confirmation",
  CANCEL_SERIES_CONFIRMATION = "cancel-series-confirmation",
  COMPLETE_COMPLIANCE = "complete-compliance-dialog",
  EDIT_RECURRING_INVOICE = "edit-recurring-invoice",
  CUSTOMER_BULK_EMAIL = "customer-bulk-email",
  CHANGE_EMAIL = "change-email",
  CHANGE_PHONE_NUMBER = "change-phone-number",
  PASSWORD_VERIFICATION_DIALOG = "password-verification-dialog",
  RECORD_PAYMENT = "record-payment",
  REFUND_PAYMENT = "refund-payment",
  COPY_LINK_DIALOG = "copy-link-dialog",
  DELETE_QUOTE_CONFIRMATION = "delete-quote-confirmation",
  SEND_QUOTE_CONFIRMATION = "send-quote-confirmation",
  CANCEL_QUOTE_CONFIRMATION = "cancel-quote-confirmation",
  QUOTES_PAGINATION = "quotes-pagination",
  QUOTE_SENT = "quote-sent-confirmation",
  QUOTE_SCHEDULED = "quote-scheduled-confirmation",
  DELETE_QUOTE_ITEM_CONFIRMATION = "delete-quote-item-confirmation",
  DELETE_QUOTE_MODIFIER_CONFIRMATION = "delete-quote-modifier-confirmation",
  SEND_QUOTE_APPROVAL = "send-quote-approval",
  WELCOME_TO_FINLI = "welcome-to-finli",
  PARTNERSHIP_AD = "partnership-ad",
  ADMIN_PUF_CREATION = "admin-puf-creation",
}

export enum Genders {
  M = "M",
  F = "F",
  OTHER = "OTHER",
  PREFER_NOT_TO_SAY = "PREFER_NOT_TO_SAY",
}

export enum SeriesFrequency {
  DAILY = "DAILY",
  WEEKLY = "WEEKLY",
  MONTHLY = "MONTHLY",
  ANNUALLY = "ANNUALLY",
}

export enum ReadableSeriesFrequency {
  DAILY = "Day",
  WEEKLY = "Week",
  MONTHLY = "Month",
  ANNUALLY = "Year",
}

export enum SeriesStatus {
  NOT_STARTED = "NOT_STARTED",
  ACTIVE = "ACTIVE",
  SCHEDULED = "SCHEDULED",
  PAUSED = "PAUSED",
  COMPLETED = "COMPLETED",
  CANCELED = "CANCELED",
}

export enum InvoiceStatus {
  DRAFT = "DRAFT",
  SCHEDULED = "SCHEDULED",
  ISSUED = "ISSUED",
  COMPLETED = "COMPLETED",
  CANCELED = "CANCELED",
  DELETED = "DELETED",
  PAST_DUE = "PAST_DUE",
}

export enum QuoteStatus {
  DRAFT = "DRAFT",
  CANCELED = "CANCELED",
  DELETED = "DELETED",
  SENT = "SENT",
  APPROVED = "APPROVED",
  INVOICED = "INVOICED",
  REJECTED = "REJECTED",
}

export enum PartnerBusinessStatus {
  ACCOUNT_CREATED = "ACCOUNT_CREATED",
  PENDING_APPROVAL = "PENDING_APPROVAL",
  APPROVED = "APPROVED",
}

export enum CustomerStatus {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
  DELETED = "DELETED",
}

export enum QuantityMeasurement {
  UNIT = "UNIT",
  HOURLY = "HOURLY",
}

export enum InvoiceAction {
  SCHEDULE = "SCHEDULE",
  ISSUE = "ISSUE",
  SHARE = "SHARE",
  DELETE = "DELETE",
  // Series Specific
  PAUSE = "PAUSE",
  RESUME = "RESUME",
  CANCEL = "CANCEL",
  SKIP_CURRENT = "SKIP_CURRENT",
  DUPLICATE = "DUPLICATE",
  // Invoice Specific
  EDIT = "EDIT",
  SAVE = "SAVE",
  VIEW_PAYMENTS = "VIEW_PAYMENTS",
  RECORD_OFFLINE_PAYMENT = "RECORD_OFFLINE_PAYMENT",
  SHARE_LINK = "SHARE_LINK",
  VIEW = "VIEW",
}

export enum PaymentSeriesFrequency {
  ONCE = "ONCE",
  WEEKLY = "WEEKLY",
  MONTHLY = "MONTHLY",
}

export enum QuoteAction {
  ACCEPT = "ACCEPT",
  SCHEDULE = "SCHEDULE",
  CANCEL = "CANCEL",
  SEND = "SEND",
  RESEND = "RESEND",
  DELETE = "DELETE",
  EDIT = "EDIT",
  SAVE = "SAVE",
  SHARE_LINK = "SHARE_LINK",
  VIEW = "VIEW",
  INVOICE = "INVOICE",
  VIEW_INVOICE = "VIEW_INVOICE",
}

export enum PaymentMethodTypeEnum {
  BANK_ACCOUNT = "BANK_ACCOUNT",
  CREDIT_CARD = "CREDIT_CARD",
  APPLE_PAY = "APPLE_PAY",
  OFFLINE = "OFFLINE",
  NEW = "NEW",
}

export type AllowedPaymentMethodSelection = string | PaymentMethodTypeEnum.APPLE_PAY | PaymentMethodTypeEnum.NEW

export enum PaymentCreateMethod {
  OFFLINE = "OFFLINE",
  WALLET = "WALLET",
  ONETIME = "ONETIME",
  APPLE_PAY = "APPLE_PAY",
}

export enum PaymentCreateTarget {
  INVOICE = "INVOICE",
  ACCOUNT = "ACCOUNT",
}

export enum AccountQuery {
  // for fetching account info
  ACCOUNT_INFO = "account-info",
  ACCOUNT_TEAM_MEMBERS = "account-team-members",

  // for customer detail page
  CUSTOMER_INFO = "customer-info",
  CUSTOMER_QUOTES = "customer-quotes",
  CUSTOMER_INVOICES = "customer-invoices",
  CUSTOMER_SERIES = "customer-series",

  // for customer pagination
  CUSTOMERS_PAGINATION = "customers-pagination",

  // for payments pagination
  PAYMENTS_PAGINATION = "payments_pagination",

  //for merchant detail page
  MERCHANT_PROFILE = "merchant-profile",
  MERCHANT_INFO = "merchant-info",

  // invoice builder
  INVOICE = "invoice",
  BUILDER_ALL_CUSTOMERS = "builder-all-customers",
  BUILDER_ALL_CUSTOMER_CATEGORIES = "builder-all-customer-categories",
  BUILDER_ALL_PRICE_ITEMS = "builder-all-price-items",
  BUILDER_ALL_PRICE_MODIFIERS = "builder-all-price-modifiers",
  BUILDER_ALL_TAXES = "builder-all-taxes",

  // quote builder
  QUOTE = "quote",

  // products pagination
  PRICE_ITEMS_PAGINATION = "price-items-pagination",

  // discount/surcharge pagination
  PRICE_MODIFIERS_PAGINATION = "price-modifiers-pagination",

  // discount/surcharge pagination
  TAXES_PAGINATION = "taxes-pagination",

  // payment qr code
  PAYMENT_QR_CODE = "payment-qr-code",

  // invoices
  INVOICES_PAGINATION = "invoices-pagination",
  QUOTES_PAGINATION = "quotes-pagination",

  // series
  SERIES_PAGINATION = "series-pagination",

  ALL_TAGS = "all-tags",
  GLOBAL_SEARCH = "global-search",
  ANALYTICS = "analytics",
  ACTIVITIES = "activities",
  ADS = "ads",
  PAYMENTS = "payments",
  SUBDOMAIN = "subdomain",
  CONTACT_SEARCH = "contact-search",

  PBL_ACCOUNT_INFO = "pbl-account-info",
}

export enum PartnershipQuery {
  PARTNERSHIP_INFO = "partnership-info",
  RECEIVABLES_PAGINATION = "receivables-pagination",
  BUSINESS = "partnership-business",
  BUSINESS_TEAMS = "partnership-business-teams",
  BUSINESS_RECEIVABLES = "partnership-business-receivables",
  BUSINESSES_PAGINATION = "business-pagination",
  BUSINESSES_INVITES = "business-invites",
  ANALYTICS = "partnership-dashboard-analytics",
  TEAM = "partnership-team",
  TEAMS = "partnership-teams",
  ALL_TEAM_MEMBERS = "partnership-all-team-members",
  TEAM_MEMBERS = "partnership-team-members",
  TEAM_MEMBER = "partnership-team-member",
  TEAM_BUSINESSES = "partnership-team-businesses",
  TEAM_MEMBER_TEAMS = "partnership-team-member-teams",
}

export enum AdminQuery {
  //for merchant pagination (admin)
  MERCHANTS_PAGINATION = "admin-merchants-pagination",

  //for super invoices paginations (admin)
  INVOICES_PAGINATION = "admin-invoices-pagination",
  QUOTES_PAGINATION = "admin-quotes-pagination",

  //for payments pagination (admin)
  PAYMENTS_PAGINATION = "admin-payments-pagination",
  PAYMENTS_PAYOUT_READY = "admin-payments-payout-ready",

  //for users pagination (admin)
  USERS_PAGINATION = "admin-users-pagination",
  SPECIFIC_USER = "admin-specific-user",
  USER_INVOICES = "admin-user-invoices",
  USER_QUOTES = "admin-user-quotes",
  USER_NOTIFICATIONS = "admin-user-notifications",
  PARTNERSHIPS = "admin-partnerships",
  SPECIFIC_PARTNER = "admin-specific-partner",
  PARTNER_ACCOUNTS = "admin-partner-accounts",
  PARTNER_TEAMS = "admin-partner-teams",
  PARTNER_INVOICES = "admin-partner-invoices",
  PARTNER_QUOTES = "admin-partner-quotes",
  PARTNER_ADS = "admin-partner-ads",

  EVENTS_PAGINATION = "admin-events-pagination",
}

export enum UserQuery {
  USER_INFO = "user-info",
  ENROLL_OTP = "enroll-otp",
  PIL_INVOICE = "pil-invoice",
  INVOICES = "all-user-invoices",
  PAYMENT = "user-payment",
  PAYMENTS = "all-user-payments",
  QUOTE = "user-quote",
  QUOTES = "all-user-quotes",
  RELATED_INVOICES = "invoice-related-invoices",
}

export interface ScheduledBusinessPayments {
  paymentsByBusiness: PaymentsByBusiness
}

export interface PaymentsByBusiness {
  [businessId: string]: {
    payments: ScheduledPayment[]
    business_name: string
    isAutopay: false
  }
}

export enum ScheduledPaymentTypeEnum {
  RECURRING_PBL = "RECURRING_PBL",
  PBL = "PBL",
  PIL = "PIL",
}

export interface ScheduledPayment {
  invoice_id?: string
  invoice_number?: number
  business_name: string
  payin_at: string
  amount: number
  id: string
  invoice_item_names: string[]
  status?: string
  type: ScheduledPaymentTypeEnum
}

export enum WalletQuery {
  PLAID_LINK_TOKEN = "plaid-link-token",
  FUTURE_PAYMENTS = "future-payments",
}

export const Query = {
  account: AccountQuery,
  partnership: PartnershipQuery,
  admin: AdminQuery,
  user: UserQuery,
  wallet: WalletQuery,
}

export enum ServiceTypes {
  HOME_MAINTENANCE = "HOME_MAINTENANCE",
  CARE = "CARE",
  ENRICHMENT_EDUCATION = "ENRICHMENT_EDUCATION",
  FREELANCE = "FREELANCE",
  FITNESS_WELLNESS = "FITNESS_WELLNESS",
  PROFESSIONAL_SERVICES = "PROFESSIONAL_SERVICES",
  OTHER = "OTHER",
}

export const ServicesList = [
  {
    title: "Home Maintenance",
    value: ServiceTypes.HOME_MAINTENANCE,
    description: "Landscaping, gardening, home maintenance office maintenance, pest control, cleaning",
  },
  {
    title: "Care",
    value: ServiceTypes.CARE,
    description: "Senior, child, pet",
  },
  {
    title: "Enrichment/Education",
    value: ServiceTypes.ENRICHMENT_EDUCATION,
    description: "After-school, music, martial arts, tutoring, theater, dance",
  },
  {
    title: "Freelance",
    value: ServiceTypes.FREELANCE,
    description: "Website creation, graphic design, content writing",
  },
  {
    title: "Fitness/Wellness",
    value: ServiceTypes.FITNESS_WELLNESS,
    description: "Therapy, counseling, training, coaching",
  },
  {
    title: "Professional Services",
    value: ServiceTypes.PROFESSIONAL_SERVICES,
    description: "Accounting, legal, insurance, marketing",
  },
  {
    title: "Other",
    value: ServiceTypes.OTHER,
    description: "SaaS, retail, commerce, etc",
  },
]

export const stateNamesAndAbbrs = [
  { ABBR: "AL", NAME: "Alabama" },
  { ABBR: "AK", NAME: "Alaska" },
  { ABBR: "AZ", NAME: "Arizona" },
  { ABBR: "AR", NAME: "Arkansas" },
  { ABBR: "CA", NAME: "California" },
  { ABBR: "CO", NAME: "Colorado" },
  { ABBR: "CT", NAME: "Connecticut" },
  { ABBR: "DE", NAME: "Delaware" },
  { ABBR: "DC", NAME: "District Of Columbia" },
  { ABBR: "FL", NAME: "Florida" },
  { ABBR: "GA", NAME: "Georgia" },
  { ABBR: "HI", NAME: "Hawaii" },
  { ABBR: "ID", NAME: "Idaho" },
  { ABBR: "IL", NAME: "Illinois" },
  { ABBR: "IN", NAME: "Indiana" },
  { ABBR: "IA", NAME: "Iowa" },
  { ABBR: "KS", NAME: "Kansas" },
  { ABBR: "KY", NAME: "Kentucky" },
  { ABBR: "LA", NAME: "Louisiana" },
  { ABBR: "ME", NAME: "Maine" },
  { ABBR: "MD", NAME: "Maryland" },
  { ABBR: "MA", NAME: "Massachusetts" },
  { ABBR: "MI", NAME: "Michigan" },
  { ABBR: "MN", NAME: "Minnesota" },
  { ABBR: "MS", NAME: "Mississippi" },
  { ABBR: "MO", NAME: "Missouri" },
  { ABBR: "MT", NAME: "Montana" },
  { ABBR: "NE", NAME: "Nebraska" },
  { ABBR: "NV", NAME: "Nevada" },
  { ABBR: "NH", NAME: "New Hampshire" },
  { ABBR: "NJ", NAME: "New Jersey" },
  { ABBR: "NM", NAME: "New Mexico" },
  { ABBR: "NY", NAME: "New York" },
  { ABBR: "NC", NAME: "North Carolina" },
  { ABBR: "ND", NAME: "North Dakota" },
  { ABBR: "OH", NAME: "Ohio" },
  { ABBR: "OK", NAME: "Oklahoma" },
  { ABBR: "OR", NAME: "Oregon" },
  { ABBR: "PA", NAME: "Pennsylvania" },
  { ABBR: "RI", NAME: "Rhode Island" },
  { ABBR: "SC", NAME: "South Carolina" },
  { ABBR: "SD", NAME: "South Dakota" },
  { ABBR: "TN", NAME: "Tennessee" },
  { ABBR: "TX", NAME: "Texas" },
  { ABBR: "UT", NAME: "Utah" },
  { ABBR: "VT", NAME: "Vermont" },
  { ABBR: "VA", NAME: "Virginia" },
  { ABBR: "WA", NAME: "Washington" },
  { ABBR: "WV", NAME: "West Virginia" },
  { ABBR: "WI", NAME: "Wisconsin" },
  { ABBR: "WY", NAME: "Wyoming" },
]

////////////////////////////////////////////////////////////////////////////////

export type EmailPhoneValues = {
  isPhone?: number
  email_or_phone?: string
}

// TODO - aleks check typing on AuthValues and LoginValues changes
export type AuthValues = EmailPhoneValues & {
  password?: string
  cell_phone?: string
  email?: string
  googleAuthToken?: string
  recaptchaToken?: string
  otc_code?: string[]
  two_fa_token?: string[]
}

export type LoginValues = {
  google_auth_token?: string
  cell_phone?: string
  email?: string
  password?: string
  otc_code?: string
  two_fa_token?: string
  is_account_signup?: boolean
  signup_url?: string
}

export type Wallet = {
  id: string
  created_at: string
  updated_at: string
  payout_wallet_instrument_id?: string
  wallet_instruments: WalletInstrument[]
}

export interface WalletInstrument {
  id: string
  wallet_id: string
  ach?: BankAccount
  card?: CreditCard
}

interface BaseInstrument {
  id: string
  wallet_id: string
  hasActiveSeries: boolean
}

export type FlattenedWalletInstrument = BankAccount | CreditCard

export interface CreditCard extends BaseInstrument {
  type: PaymentMethodTypeEnum.CREDIT_CARD
  brand: PaymentBrand
  exp_month: number
  exp_year: number
  first_name: string
  id: string
  last_four_digits: string
  last_name: string
  zip_code: string
  name: string
}

export interface BankAccount extends BaseInstrument {
  type: PaymentMethodTypeEnum.BANK_ACCOUNT
  routing_number: string
  bank_name: string
  account_name: string
  account_type: string
  account_subtype: string
  plaid_link_type: any
  plaid_link_token?: string
  address1: string
  address2?: string
  city: string
  state: string
  zip_code: string
  last_four_digits: string
  name: string
}

export interface AdminPayment {
  is_ready_for_payout: boolean
  is_cc_return_eligible: boolean
  is_cc_unreturn_eligible: boolean
  invoice_number: number
  payment_target: string
  payment_type: string
  transactions: Transaction[]
  payer_instrument_details: PayerInstrumentDetails
  payment_id: string
  payer_id: string
  payer_type: string
  amount: number
  status: string
  invoice_id: string
  initiation_date: string
  invoice_due_date: string
  payer_contact: string
  payer_name: string
  payer_email: string
  payer_phone?: string
  merchant_name: string
  merchant_id: string
  brand?: PaymentBrand
  merchant_compliance_status: string
  invoice_status: string
  payment_method: string
  e2eidstring?: string
  funds_received_at?: any
  payout_initiated_at?: any
  allowable_actions: {
    main: PaymentAction[]
    sub: PaymentAction[]
    table: PaymentAction[]
  }
}

export interface PayerInstrumentDetails {
  offline?: boolean
  failed_transactions: number
  last_four_digits?: string
  routing_number?: string
  bank_name?: string
  expiration?: string
  brand?: string
  deleted_at?: string
}

export interface Transaction {
  transaction_id?: string
  amount?: number
  created_at: string
  completed_at?: null | string | string
  error_reason?: string
  type?: string
  returned_at?: any
  failed_at?: string
  e2e?: null | string | string
  cybersource?: any
  status?: null | string | string
}

export interface AdminBasicUser {
  name: string
  created_at: string
  id: string
  status: string
  login_method: string
  types: string[]
  is_magic_code_overridden: boolean
  signup_url?: string
  last_login_at?: string
  contact_methods: {
    cell_phone?: any
    email?: string
  }
}

export interface AdminAssociatedMerchant {
  compliance_status: ComplianceStatusEnum
  id: string
  public_name: string
  status: string
  role_type: string
  signup_date?: string
}

export enum PartnershipBillingTypeEnum {
  PARTNER_USING_FINLI = "PARTNER_USING_FINLI",
  NO_FEE = "NO_FEE",
  FULL_FEE = "FULL_FEE",
  EXTERNAL_FEE = "EXTERNAL_FEE",
}

export interface AdminAssociatedPartnership {
  id: string
  routing_number: string
  name: string
  renewal_date?: string | null
  billing_type?: PartnershipBillingTypeEnum
  invoice_items: any | null
  role_type: AccountRoles
  associated_merchants_count: number
}

export type AdminUserNotification = {
  id: string
  scheduled_at: string
  template: string
  sms_substitutions: Record<string, any> | null
  email_substitutions: Record<string, any> | null
  completed_at: string
  attempt: number
  sms_cell_phone: string | null
  email: string | null
  email_send_at: string | null
  sms_send_at: string | null
}

export interface AdminUser extends AdminBasicUser {
  wallet_id: string
  wallet: {
    bank_accounts: any[]
    credit_cards: any[]
  }
  associatedMerchants: AdminAssociatedMerchant[]
  associatedPartnerships: AdminAssociatedPartnership[]
  notifications: AdminUserNotification[]
  is_otc_overridden?: boolean
}

export type AdminPartner = {
  id: string
  name: string
  created_at: string
  routing_number: string
  invoice_items: InvoiceItem[]
  subscriptions: {
    subscription_name: string
    addons: string[]
  }[]
  renewal_date: string
  primary_color?: string
  secondary_color?: string
  avatar_url?: string
  white_logo_url?: string
  fav_icon_url?: string
  billing_type?: PartnershipBillingTypeEnum
  saas_agreement_copy?: string
  does_collect_merchant_demographic_data?: boolean
  contract_term: {
    start_date: string
    end_date: string
  }
  public_email?: string
  finli_support_email?: string
  saas_priceitem_price: number
  associated_merchants_count?: string
  merchants?: AdminAssociatedMerchant[]
  puf_account?: {
    id?: string
    public_name?: string
    saas_priceitem_price?: number
  }

  current_transactions_sum?: number
  max_transactions_sum?: number
  current_member_seats?: number
  max_member_seats?: number
  current_account_seats?: number
  max_account_seats?: number
  current_puf_seats?: number
}

export type AdminMerchant = {
  id: string
  merchant_name: string
  compliance_status: string
  created_at: Date
  formatted_created_at: string
  status: string
  partnership: Partnership
}

export type Merchant = {
  id: string
  ssn: string
  ein?: string
  description?: string
  legal_address1: string
  legal_address2?: string
  legal_city: string
  legal_state: string
  legal_zip_code: string
  legal_name: string
  legal_email: string
  legal_phone: string
  compliance_answer: {
    customer_type: string
    industry: string
    business_structure: BusinessStructureEnum
    ssn_last_4: string
    relationship_to_business: BusinessRelationshipEnum
    social_media: {
      platform: string
      url: string
    }[]
    state_incorporation?: string
  }
  wallet_id: string
  merchant_name: string
  compliance_status: ComplianceStatusEnum
  created_at: string
  card_surcharge_to: TransactionFeePayerEnum
  status: MerchantStatusEnum
  lifetime_revenue: string
  invoices_with_funds_received: string
  is_payout_bank_account_verified: boolean
  drafted_invoices: string
  past_due_invoices: string
  lifetime_issued_invoices: string
  pbl_payments_amount: string
  business_information: {
    tax_identifier: string
    address: string
    legal_name: string
    description?: string
    contact_info: {
      cell_phone: string
      email: string
    }
  }
  wallet: {
    payout_bank_account: string
    bank_accounts: string[]
    credit_cards: string[]
  }
  associated_users: [
    {
      id: string
      email: string
      cell_phone?: string
      type: string
      name: string
      role_id: string
    },
  ]
  subscription_payment_method: string
  subscription_plan: string
  subdomain: string
  is_donation_pbl: string

  partnership?: Partnership
}

export type AssociatedMerchant = {
  business_name: string
  is_connected: boolean
  business_avatar: string
  customer_id: string
}

export type User = {
  has_password?: boolean
  has_seen_updates: boolean
  adminRole: string | null
  associatedMerchants: AssociatedMerchant[]
  avatar_url?: any | null
  id: string
  created_at: string
  updated_at: string
  email?: string
  name?: string
  cell_phone: string
  home_phone: any
  work_phone: any
  address1: string
  address2?: string
  city: string
  state: string
  zip_code: string
  gender: keyof typeof Genders | string
  is_verified_email: boolean
  is_payer: boolean
  do_receive_email_notifications: boolean
  date_of_birth: Date | string
  is_verified_sms: boolean
  do_receive_sms_notifications: boolean
  email_verification_code: string
  sms_verification_code?: any
  sms_verification_code_expires_at?: any
  password_reset_expires_at?: any
  reset_password_code?: any
  one_time_password_secret?: any
  password_bad_attempt_count: number
  last_password_bad_attempt_at?: any
  status: string
  google_user_id?: any
  signup_url?: any
  is_not_shady?: boolean
  has_signed_up?: boolean
  timezone: string | keyof typeof Timezone
  token: string
  roles: Role[]
  compliance_answer?: any
  is_2fa_enrolled?: boolean
  wallet_id?: string
  wallet: Wallet
  must_verify_email: boolean
  must_verify_sms: boolean
  must_finish_user_setup: boolean
  is_pbl_user: boolean
}

export type AnalyticsPayment = {
  id: string
  amount: number
  payin_at: string
  account_customer_id?: string
  payer_name?: string
  email?: string
  cell_phone?: string
}

export type Analytics = {
  summary: {
    total_sales: number // integer
    refunds_initiated: number
    payments_received: number
  }
  payments_processing: {
    total: number
    results: AnalyticsPayment[]
  }
  invoices_past_due: {
    balance: number
    total: number
    results: {
      id: string
      amount: number
      due_at: string
      invoice_number: number
      account_customer_id?: string
      account_customer_name?: string
    }[]
  }
  settlements: {
    results: {
      date: string
      total: number
      sum: number
      payments: AnalyticsPayment[]
    }[]
  }
  events: {
    total: number
    results: {
      date: string
      events: {
        id: string
        type: EventLog
        performer_name?: string
        performer_avatar_url?: string
        invoice_id?: string
        invoice_number?: number
        priceitem_id?: string
        priceitem_name?: string
        priceitem_qty?: number
        customer_id?: string
        customer_name?: string
        role_id?: string
        role_type?: string
        role_user_name?: string
      }[]
    }[]
  }
}

export type PartnerAnalytics = {
  accounts: PartnerAnalyticsAccounts
  receivables: {
    total: number
    total_amount_settled: number
    total_amount_processing: number
    total_amount_online: number
    total_amount_offline: number
    total_ach: number
    total_card: number
    total_cash: number
    total_check: number
    total_zelle: number
    total_paypal: number
    total_venmo: number
    total_other: number
  }
  pbl: PartnerAnalyticsPbl
  invoices: PartnerAnalyticsInvoices
}

export type PartnerAnalyticsAccounts = {
  total: number
  account_created: number
  pending_approval: number
  approved: number
}

export type PartnerAnalyticsInvoices = {
  total_invoices_count: number
  invoices_pastdue_count: number
  invoices_paid_count: number
  invoices_outstanding_count: number
}

export type PartnerAnalyticsPbl = {
  total_amount_received: number
  total_amount_recurring: number
}

export type Role = {
  id: string
  created_at: string
  updated_at: string
  type: keyof typeof AccountRoles
  title?: string
  account?: Account
  partnership?: Partnership
  isTemporary?: boolean
  account_id?: string | null
  user_id?: string | null
  partnership_id?: string | null
}
export type Partnership = {
  id?: string
  name?: string
  avatar_url?: string
  white_logo_url?: string
  fav_icon_url?: string
  created_at?: string
  updated_at?: string
  primary_color?: string
  secondary_color?: string
  routing_number?: string
  public_email?: string
  finli_support_email?: string
  billing_type?: PartnershipBillingTypeEnum
  does_collect_merchant_demographic_data?: boolean
  saas_priceitem_price?: number
  saas_agreement_copy?: string
}

export type Integration = {
  id: Required<Account["id"]>
  data: any
  type: string
  status: string
  account_id: string
  created_at: string
  updated_at: string
}

export type Account = {
  id?: string
  invoice_number?: number
  quote_number?: number
  subdomain?: string
  isFuf?: boolean
  created_at?: string
  updated_at?: string
  avatar_url?: any | null
  legal_name?: string
  legal_email?: any
  legal_phone?: string
  public_name?: string
  public_email?: any
  public_phone?: string
  legal_address1?: any
  legal_address2?: any
  legal_city?: any
  legal_state?: any
  legal_zip_code?: any
  public_address1?: any
  public_address2?: any
  public_city?: any
  public_state?: any
  public_zip_code?: any
  description?: any
  business_structure?: keyof typeof BusinessStructureEnum | string
  state_incorporation?: any
  service_type?: string
  tax_id?: string
  compliance_status?: ComplianceStatusEnum
  plan?: string
  deleted_at?: any
  timezone?: string | keyof typeof Timezone
  compliance_answer?: any
  should_show_tutorial?: boolean
  should_show_refer_a_business?: boolean
  should_show_failed_compliance?: boolean
  ein?: string
  does_show_ein_to_customers?: boolean
  ssn?: string
  customer_type?: string
  last_four_ssn?: string
  relationship_to_business?: string
  same_as_personal_address?: string
  display_address?: boolean
  website_url?: string
  card_surcharge_to?: keyof typeof CardSurchargeToEnum | string
  links?: { platform: string; url: string }[]
  wallet?: Wallet
  wallet_id?: string
  saas_next_billing_date?: string
  saas_autopay_wallet_instrument_id?: string
  saas_series_type?: SaasSeriesEnum
  status?: AccountStatuses
  persona_inquiry_id?: string
  persona_id_completed_at?: string
  partnership?: Partnership
  partnership_id?: Partnership["id"]
  integrations?: Integration[]
  is_donation_pbl?: boolean
  user_demographic?: Record<string, any>
  is_owned_by_partnership?: boolean
  is_puf?: boolean
}

export interface TeamMember {
  id: string
  avatar_url?: string
  name: string
  email?: string | null
  cell_phone?: string | null
  status: string
  role: {
    id: string
    type: AccountRoles
  }
  do_receive_email_notifications: boolean
  do_receive_sms_notifications: boolean
  is_using_google_auth: boolean
  is_changeable: boolean
}

export interface Tag {
  id: string
  name: string
}

export type PriceItemValues = {
  name: string
  price: string
  description: string
  id?: string
  is_adhoc: boolean
  is_track_inventory: boolean
  inventory_on_hand: number | string
  inventory_reorder_point: number | string
  from_builder?: boolean
  inventory_last_updated_at?: string
  is_taxable: boolean
  category?: PriceItemCategory
}

export interface InvoiceCustomer {
  address: string
  series_autopay_string: string
  tags: never[]
  id?: string
  name?: string
  status?: typeof CustomerStatus | string
  email?: string | null
  cell_phone?: string | null
  avatar_url?: any | null
  address_1?: string
  address_2?: string
  city?: string
  state?: string
  zip_code?: string
  user?: {
    email?: string | null
    cell_phone?: string | null
  }
  wallet_id?: string
  created_at: string
  updated_at: string
  note?: any
  note_last_updated?: any
  address1?: string
  address2?: string
  card_surcharge_to: any
  account_id: string
  user_id: string
  is_connected: boolean
  company_name?: any
  payer_account_id?: any
  payer_partnership_id?: any
  category_names?: string
  autopay_wallet_instrument_id?: any
  is_taxable?: boolean
}

export interface QuoteItem extends InvoiceItem {}
export interface QuoteCustomer extends InvoiceCustomer {}

export interface AccountCustomer {
  id: string
  city: string | null
  name: string
  note: string | null
  state: string | null
  status: string
  user_id: string
  address1: string | null
  address2: string | null
  zip_code: string | null
  account_id: string
  created_at: string
  updated_at: string
  company_name: string | null
  is_connected: boolean
  category_names: string[] | null
  payer_account_id: string | null
  card_surcharge_to: TransactionFeePayerEnum
  note_last_updated: string | null
  payer_partnership_id: string | null
  autopay_wallet_instrument_id: string | null
}

export type AccountCustomerCategory = {
  account_customer_id: string
  created_at: string
  id: string
  name: string
  note: string | null
  note_last_updated: string | null
  status: string
  updated_at: string
}

export interface ItemPriceModifier {
  id?: string
  name?: string
  value_flat?: string | number
  value_pct?: string | number
  is_tax?: boolean
  account_pricemodifier_id?: string
}

export interface InvoiceItem {
  id?: string
  account_customer_category_id?: string | null
  account_customer_category_name?: string | null
  account_customer_category: AccountCustomerCategory | null
  account_priceitem_id: string
  account_pricemodifier_id?: string | null
  pricemodifier_name?: string
  pricemodifier_value_flat?: string | number
  pricemodifier_value_pct?: string | number
  pricemodifiers?: ItemPriceModifier[]
  taxes?: ItemPriceModifier[]
  name?: string
  price: number
  description: string
  quantity: number
  quantity_measurement: string
  sort_order?: number
  is_recurring?: boolean
  is_editing?: boolean
  is_track_inventory?: boolean
  inventory_on_hand?: number
  inventory_reorder_point?: number
  is_taxable?: boolean
}

export type PblPayment = {
  id: string
  created_at: string
  updated_at: string
  amount: number
  refund_amount: number
  transaction_fee: number
  transaction_fee_payer: string
  invoice_id: null
  payer_wallet_instrument_id: string
  payment_note: string
  paid_offline_type: null
  was_automatic_payment: boolean
  payin_at: string
  payout_at: null
  is_hold_for_approval: boolean
  account_id: string
  payment_series_frequency?: PaymentSeriesFrequency
}

export type Payment = {
  id: string
  transactions: any[]
  amount: number
  autopay: boolean
  created_at: string
  payer_id?: string
  business_name?: string
  invoice_number: number
  brand?: string
  payer_name: string
  customer_id?: string
  payer_customer_id?: string
  payer_contact: string
  last_four_digits: string
  formatted_payment_date: string
  invoice_id?: string | null
  paid_offline_type: PaidOfflineType
  payer_wallet_instrument_id: string | null
  payment_date?: string
  payment_note?: string
  payin_at: string
  payment_method: string
  payment_target: string
  payment_type: PaymentMethodTypeEnum
  payment_relation_to_due_date: string
  refund_amount: number
  refunded_amount: number
  transaction_fee: number | null
  transaction_fee_payer: string
  updated_at: string
  was_automatic_payment: boolean
  status: PaymentStatus
  is_hold_for_approval: boolean
  fee?: number | null
  invoice_due_date?: string
  payout_at?: string
  method_description?: string
  series_details?: {
    payment_series_id?: string
    frequency?: PaymentSeriesFrequency
    canceled_at?: string
  }
  was_donation?: boolean
  subdomain?: string
  allowable_actions: {
    main: PaymentAction[]
    sub: PaymentAction[]
    table: PaymentAction[]
  }
  tax?: number
}

export type OutstandingInvoice = {
  due_at: string | Date
  invoice_number: number
  amount: number
  invoice_id: string
  payer_wallet_instrument_id: string
  payin_at: string | Date
  transaction_fee: number
  was_automatic_payment: boolean
  items: string[]
}

export type Invoice = {
  id: string
  account_customer_id?: string
  account_pricemodifier_id?: string
  card_surcharge_to: TransactionFeePayerEnum
  pricemodifier_name?: string
  pricemodifier_value_flat?: string | number
  pricemodifier_value_pct?: string | number
  expected_issue_at?: string
  expected_notify_at?: string | null
  events?: any[]
  due_at?: string
  statement_period_start?: string
  statement_period_end?: string
  recipient_message?: string
  internal_note?: string
  items: InvoiceItem[]
  customers: InvoiceCustomer[]
  customer?: InvoiceCustomer | null
  account?: Account
  series?: any
  status?: string | InvoiceStatus
  sort_order?: string | number
  invoice_number?: number
  payment_status?: PaymentStatus

  modified_after_issued_at?: string
  is_recipient_message_recurring?: boolean
  is_pricemodifier_recurring?: boolean

  has_changed?: boolean
  has_sync_data_changed?: boolean
  sync_mode?: "never" | "always" | "ask"
  should_sync_data?: boolean
  force_save?: boolean
  payments?: Payment[]

  is_recurring?: boolean
  use_statement_period?: boolean
  allowable_actions?: {
    main: InvoiceAction[]
    sub: InvoiceAction[]
    table: InvoiceAction[]
  }
  is_recurring_modifiable?: boolean
  is_series_modifiable?: boolean
  is_readonly?: boolean
  wallet?: { wallet_instruments: WalletInstrument[] }

  is_send_via_email?: boolean
  is_send_via_sms?: boolean
  email_cc_recipients?: string[]
  taxes: Tax[]
}

export type Quote = {
  created_at?: string
  updated_at?: string
  total_amount?: number
  quote_number?: number
  quote_valid_until?: string
  quote_id?: string
  items: QuoteItem[]
  sent_at?: string
  invoice_id?: string
  allowable_actions?: {
    main: QuoteAction[]
    sub: QuoteAction[]
    table: QuoteAction[]
  }
  status?: string | QuoteStatus
} & Omit<
  Invoice,
  | "items"
  | "status"
  | "invoice_number"
  | "expected_issue_at"
  | "expected_notify_at"
  | "due_at"
  | "payments"
  | "series"
  | "statement_period_end"
  | "statement_period_start"
  | "wallet"
>

export enum CardSurchargeToEnum {
  ACCOUNT = "ACCOUNT",
  USER = "USER",
}

export type PartnerBusiness = {
  filter(arg0: (business: any) => boolean): unknown
  id: string
  signup_status: PartnerBusinessStatus
  public_name: string
  compliance_updated_at: string
  contact: {
    name: string
    email: string
    phone: string
  }
  teams: PartnerTeam[]
  invoices_paid: number
  invoices_sent: number
  invoice_count: number
  invoices_avg_amt: number
  invoices_unpaid_count: number
  total_amount_settled: number
  total_amount_processed: number
  total_invoice_payments: number
  total_pbl_payments: number
  autopay_customer_count: number
  recurring_pbl_count: number
}

export type PartnerReceivable = {
  invoice_id: string
  payment_id?: string
  account_name: string
  payment_date?: string
  settled_date: any
  payment_amount: number
  refund_amount?: number
  net_amount: number
  payment_method?: string
  autopay?: boolean
  invoice_created_at: string
  invoice_status: string
  due_date: string
  payment_status: string
}

export type Paginated<X> = {
  results: X[]
  pageInfo: {
    total: number
    page: number
    pageSize: number
    page_size: number
    totalPages: number
  }
}

export enum PaymentStatus {
  SCHEDULED = "SCHEDULED",
  PROCESSING = "PROCESSING",
  PAID = "PAID",
  FAILED = "FAILED",
  REFUND_PROCESSING = "REFUND_PROCESSING",
  PARTIALLY_REFUNDED = "PARTIALLY_REFUNDED",
  REFUNDED = "REFUNDED",
}

export enum PaymentSourceEnum {
  INVOICE = "INVOICE",
  PAY_BY_LINK = "PAY_BY_LINK",
}

export enum ComplianceStatusEnum {
  PASS = "PASS",
  NOT_SUBMITTED = "NOT_SUBMITTED",
  FAIL = "FAIL",
  PENDING = "PENDING",
  PROVISIONAL = "PROVISIONAL",
}

export enum MerchantStatusEnum {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
  SUSPENDED = "SUSPENDED",
}

export enum PaidOfflineType {
  ZELLE = "ZELLE",
  PAYPAL = "PAYPAL",
  VENMO = "VENMO",
  CASH = "CASH",
  CHECK = "CHECK",
  OTHER = "OTHER",
}

export enum MonthsAbbreviated {
  JAN = "01",
  FEB = "02",
  MAR = "03",
  APR = "04",
  MAY = "05",
  JUN = "06",
  JUL = "07",
  AUG = "08",
  SEP = "09",
  OCT = "10",
  NOV = "11",
  DEC = "12",
}

export enum Months {
  JANUARY = "January",
  FEBRUARY = "February",
  MARCH = "March",
  APRIL = "April",
  MAY = "May",
  JUNE = "June",
  JULY = "July",
  AUGUST = "August",
  SEPTEMBER = "September",
  OCTOBER = "October",
  NOVEMBER = "November",
  DECEMBER = "December",
}

export enum Timezone {
  "America/Argentina/ComodRivadavia" = "America/Argentina/ComodRivadavia",
  "America/Atka" = "America/Atka",
  "America/Buenos_Aires" = "America/Buenos_Aires",
  "America/Catamarca" = "America/Catamarca",
  "America/Coral_Harbour" = "America/Coral_Harbour",
  "America/Cordoba" = "America/Cordoba",
  "America/Ensenada" = "America/Ensenada",
  "America/Fort_Wayne" = "America/Fort_Wayne",
  "America/Indianapolis" = "America/Indianapolis",
  "America/Jujuy" = "America/Jujuy",
  "America/Knox_IN" = "America/Knox_IN",
  "America/Louisville" = "America/Louisville",
  "America/Mendoza" = "America/Mendoza",
  "America/Montreal" = "America/Montreal",
  "America/Porto_Acre" = "America/Porto_Acre",
  "America/Rosario" = "America/Rosario",
  "America/Santa_Isabel" = "America/Santa_Isabel",
  "America/Shiprock" = "America/Shiprock",
  "America/Virgin" = "America/Virgin",
  "America/Danmarkshavn" = "America/Danmarkshavn",
  "America/Scoresbysund" = "America/Scoresbysund",
  "America/Godthab" = "America/Godthab",
  "America/Thule" = "America/Thule",
  "America/New_York" = "America/New_York",
  "America/Chicago" = "America/Chicago",
  "America/North_Dakota/Center" = "America/North_Dakota/Center",
  "America/North_Dakota/New_Salem" = "America/North_Dakota/New_Salem",
  "America/North_Dakota/Beulah" = "America/North_Dakota/Beulah",
  "America/Denver" = "America/Denver",
  "America/Los_Angeles" = "America/Los_Angeles",
  "America/Juneau" = "America/Juneau",
  "America/Sitka" = "America/Sitka",
  "America/Metlakatla" = "America/Metlakatla",
  "America/Yakutat" = "America/Yakutat",
  "America/Anchorage" = "America/Anchorage",
  "America/Nome" = "America/Nome",
  "America/Adak" = "America/Adak",
  "America/Phoenix" = "America/Phoenix",
  "America/Boise" = "America/Boise",
  "America/Indiana/Indianapolis" = "America/Indiana/Indianapolis",
  "America/Indiana/Marengo" = "America/Indiana/Marengo",
  "America/Indiana/Vincennes" = "America/Indiana/Vincennes",
  "America/Indiana/Tell_City" = "America/Indiana/Tell_City",
  "America/Indiana/Petersburg" = "America/Indiana/Petersburg",
  "America/Indiana/Knox" = "America/Indiana/Knox",
  "America/Indiana/Winamac" = "America/Indiana/Winamac",
  "America/Indiana/Vevay" = "America/Indiana/Vevay",
  "America/Kentucky/Louisville" = "America/Kentucky/Louisville",
  "America/Kentucky/Monticello" = "America/Kentucky/Monticello",
  "America/Detroit" = "America/Detroit",
  "America/Menominee" = "America/Menominee",
  "America/St_Johns" = "America/St_Johns",
  "America/Goose_Bay" = "America/Goose_Bay",
  "America/Halifax" = "America/Halifax",
  "America/Glace_Bay" = "America/Glace_Bay",
  "America/Moncton" = "America/Moncton",
  "America/Blanc-Sablon" = "America/Blanc-Sablon",
  "America/Toronto" = "America/Toronto",
  "America/Thunder_Bay" = "America/Thunder_Bay",
  "America/Nipigon" = "America/Nipigon",
  "America/Rainy_River" = "America/Rainy_River",
  "America/Atikokan" = "America/Atikokan",
  "America/Winnipeg" = "America/Winnipeg",
  "America/Regina" = "America/Regina",
  "America/Swift_Current" = "America/Swift_Current",
  "America/Edmonton" = "America/Edmonton",
  "America/Vancouver" = "America/Vancouver",
  "America/Dawson_Creek" = "America/Dawson_Creek",
  "America/Fort_Nelson" = "America/Fort_Nelson",
  "America/Creston" = "America/Creston",
  "America/Pangnirtung" = "America/Pangnirtung",
  "America/Iqaluit" = "America/Iqaluit",
  "America/Resolute" = "America/Resolute",
  "America/Rankin_Inlet" = "America/Rankin_Inlet",
  "America/Cambridge_Bay" = "America/Cambridge_Bay",
  "America/Yellowknife" = "America/Yellowknife",
  "America/Inuvik" = "America/Inuvik",
  "America/Whitehorse" = "America/Whitehorse",
  "America/Dawson" = "America/Dawson",
  "America/Cancun" = "America/Cancun",
  "America/Merida" = "America/Merida",
  "America/Matamoros" = "America/Matamoros",
  "America/Monterrey" = "America/Monterrey",
  "America/Mexico_City" = "America/Mexico_City",
  "America/Ojinaga" = "America/Ojinaga",
  "America/Chihuahua" = "America/Chihuahua",
  "America/Hermosillo" = "America/Hermosillo",
  "America/Mazatlan" = "America/Mazatlan",
  "America/Bahia_Banderas" = "America/Bahia_Banderas",
  "America/Tijuana" = "America/Tijuana",
  "America/Nassau" = "America/Nassau",
  "America/Barbados" = "America/Barbados",
  "America/Belize" = "America/Belize",
  "America/Costa_Rica" = "America/Costa_Rica",
  "America/Havana" = "America/Havana",
  "America/Santo_Domingo" = "America/Santo_Domingo",
  "America/El_Salvador" = "America/El_Salvador",
  "America/Guatemala" = "America/Guatemala",
  "America/Port-au-Prince" = "America/Port-au-Prince",
  "America/Tegucigalpa" = "America/Tegucigalpa",
  "America/Jamaica" = "America/Jamaica",
  "America/Martinique" = "America/Martinique",
  "America/Managua" = "America/Managua",
  "America/Panama" = "America/Panama",
  "America/Cayman" = "America/Cayman",
  "America/Puerto_Rico" = "America/Puerto_Rico",
  "America/Miquelon" = "America/Miquelon",
  "America/Grand_Turk" = "America/Grand_Turk",
  "America/Argentina/Buenos_Aires" = "America/Argentina/Buenos_Aires",
  "America/Argentina/Cordoba" = "America/Argentina/Cordoba",
  "America/Argentina/Salta" = "America/Argentina/Salta",
  "America/Argentina/Tucuman" = "America/Argentina/Tucuman",
  "America/Argentina/La_Rioja" = "America/Argentina/La_Rioja",
  "America/Argentina/San_Juan" = "America/Argentina/San_Juan",
  "America/Argentina/Jujuy" = "America/Argentina/Jujuy",
  "America/Argentina/Catamarca" = "America/Argentina/Catamarca",
  "America/Argentina/Mendoza" = "America/Argentina/Mendoza",
  "America/Argentina/San_Luis" = "America/Argentina/San_Luis",
  "America/Argentina/Rio_Gallegos" = "America/Argentina/Rio_Gallegos",
  "America/Argentina/Ushuaia" = "America/Argentina/Ushuaia",
  "America/Aruba" = "America/Aruba",
  "America/La_Paz" = "America/La_Paz",
  "America/Noronha" = "America/Noronha",
  "America/Belem" = "America/Belem",
  "America/Santarem" = "America/Santarem",
  "America/Fortaleza" = "America/Fortaleza",
  "America/Recife" = "America/Recife",
  "America/Araguaina" = "America/Araguaina",
  "America/Maceio" = "America/Maceio",
  "America/Bahia" = "America/Bahia",
  "America/Sao_Paulo" = "America/Sao_Paulo",
  "America/Campo_Grande" = "America/Campo_Grande",
  "America/Cuiaba" = "America/Cuiaba",
  "America/Porto_Velho" = "America/Porto_Velho",
  "America/Boa_Vista" = "America/Boa_Vista",
  "America/Manaus" = "America/Manaus",
  "America/Eirunepe" = "America/Eirunepe",
  "America/Rio_Branco" = "America/Rio_Branco",
  "America/Santiago" = "America/Santiago",
  "America/Punta_Arenas" = "America/Punta_Arenas",
  "America/Bogota" = "America/Bogota",
  "America/Curacao" = "America/Curacao",
  "America/Lower_Princes" = "America/Lower_Princes",
  "America/Kralendijk" = "America/Kralendijk",
  "America/Guayaquil" = "America/Guayaquil",
  "America/Cayenne" = "America/Cayenne",
  "America/Guyana" = "America/Guyana",
  "America/Asuncion" = "America/Asuncion",
  "America/Lima" = "America/Lima",
  "America/Paramaribo" = "America/Paramaribo",
  "America/Port_of_Spain" = "America/Port_of_Spain",
  "America/Anguilla" = "America/Anguilla",
  "America/Antigua" = "America/Antigua",
  "America/Dominica" = "America/Dominica",
  "America/Grenada" = "America/Grenada",
  "America/Guadeloupe" = "America/Guadeloupe",
  "America/Marigot" = "America/Marigot",
  "America/Montserrat" = "America/Montserrat",
  "America/St_Barthelemy" = "America/St_Barthelemy",
  "America/St_Kitts" = "America/St_Kitts",
  "America/St_Lucia" = "America/St_Lucia",
  "America/St_Thomas" = "America/St_Thomas",
  "America/St_Vincent" = "America/St_Vincent",
  "America/Tortola" = "America/Tortola",
  "America/Montevideo" = "America/Montevideo",
  "America/Caracas" = "America/Caracas",
}

export enum PaymentAction {
  EDIT = "EDIT",
  FULL_REFUND = "FULL_REFUND",
  PARTIAL_REFUND = "PARTIAL_REFUND",
  SEND_RECEIPT = "SEND_RECEIPT",
  DOWNLOAD_RECEIPT = "DOWNLOAD_RECEIPT",
  SEND_REFUND_RECEIPT = "SEND_REFUND_RECEIPT",
  DOWNLOAD_REFUND_RECEIPT = "DOWNLOAD_REFUND_RECEIPT",
  DELETE = "DELETE",
}

export enum UserPaymentAction {
  CANCEL_RECURRING = "CANCEL_RECURRING",
  VIEW = "VIEW",
  MODIFY = "MODIFY",
  CREATE_NEW_PBL = "CREATE_NEW_PBL",
  DOWNLOAD_RECEIPT = "DOWNLOAD_RECEIPT",
  MANAGE_AUTOPAY = "MANAGE_AUTOPAY",
}

export interface Customer {
  id: string
  address: string | null
  category_names: string | null
  cell_phone: string | null
  email: string | null
  name: string
  status: typeof CustomerStatus | string
  tags: Tag[] | null
  series_autopay_string: string
}

export interface PriceItem {
  id: string
  created_at: string
  formatted_created_at: string
  updated_at: string
  name: string
  description: string
  price: number
  inventory_on_hand: string
  formatted_inventory_on_hand: any
  is_track_inventory: boolean
  inventory_reorder_point: string
  from_builder?: boolean
  inventory_last_updated_at: any
  is_adhoc?: boolean
  is_taxable?: boolean
  category?: PriceItemCategory
}

export enum PriceItemCategory {
  GOODS = "GOODS",
  SERVICE = "SERVICE",
  OTHER = "OTHER",
}

export const ReadablePriceItemCategory = {
  [PriceItemCategory.GOODS]: "Goods",
  [PriceItemCategory.SERVICE]: "Services",
  [PriceItemCategory.OTHER]: "Other",
}

export interface PriceModifier {
  id: string
  type: string
  created_at: string
  updated_at: string
  name: string
  description: string | null
  value_flat: number
  value_pct: string
  account_id: string
  deleted_at: string
}

export enum PriceModifierType {
  DISCOUNT = "DISCOUNT",
  SURCHARGE = "SURCHARGE",
}

export interface Tax {
  id: string
  created_at: string
  updated_at: string
  name: string
  description: string | null
  value_pct: string
  account_id: string
  is_applied_to_all_invoices: string
}

export type TablePayment = {
  account_avatar?: string | null
  business_name?: string | null
  net_amount?: string | null
} & Pick<
  Payment,
  | "id"
  | "amount"
  | "tax"
  | "refund_amount"
  | "refunded_amount"
  | "payment_type"
  | "payment_target"
  | "invoice_id"
  | "brand"
  | "invoice_due_date"
  | "invoice_number"
  | "last_four_digits"
  | "autopay"
  | "status"
  | "payment_relation_to_due_date"
  | "payer_name"
  | "payer_contact"
  | "payer_customer_id"
  | "payer_id"
  | "payment_date"
  | "transaction_fee"
  | "payment_note"
  | "payout_at"
  | "is_hold_for_approval"
  | "formatted_payment_date"
  | "was_donation"
  | "payment_method"
  | "series_details"
  | "allowable_actions"
>

export type TablePayments = Paginated<TablePayment>

export interface TableQuote {
  total_amount: number
  business_avatar?: string | null
  business_name?: string | null
  account_customer_id: any
  account_customer: any
  payment_status: unknown
  invoice_id: any
  id: string
  account_id: string | null
  account_avatar?: string | null
  account_name?: string | null
  account_pricemodifier_id: string | null
  quote_number: number
  recipient_message: string
  pricemodifier_name: string
  status: QuoteStatus
  created_at: string | null
  sent_at: string | null
  approved_on: string | null
  rejected_on: string | null
  allowable_actions?: {
    main: QuoteAction[]
    sub: QuoteAction[]
    table: QuoteAction[]
  }
}

export type TableQuotes = Paginated<TableQuote>

export interface TableInvoice {
  id: string
  created_at: string
  updated_at: string
  card_surcharge_to: TransactionFeePayerEnum
  statement_period: string
  expected_issue_at: string
  expected_notify_at: string
  due_at: string
  recipient_message: string
  pricemodifier_name: any
  pricemodifier_value_flat: any
  pricemodifier_value_pct: any
  series_id: any
  account_customer_id: string
  account_id: string
  account_pricemodifier_id: any
  internal_note: any
  modified_after_issued_at: any
  batch_id: any
  invoice_number: number
  quote_id: any
  started_issuing_at: any
  issued_at: any
  sent_at: any
  status: string
  invoice_completed_on: any
  account_customer: AccountCustomer
  total_amount: number
  series: any
  business_name: string
  business_avatar: string
  payment_id: any
  payment_status: any
  payment_is_hold_for_approval: any
  category_names: any
  has_sync_data_changed?: boolean
  should_sync_data?: boolean
  sync_mode?: Invoice["sync_mode"]
  statement_period_start?: string
  statement_period_end?: string
  allowable_actions?: {
    main: InvoiceAction[]
    sub: InvoiceAction[]
    table: InvoiceAction[]
  }
  last_event: any
}

export type TableInvoices = Paginated<TableInvoice>

export interface PartnerTeam {
  id: string
  created_at: string
  updated_at: string
  name: string
  description: string
  deleted_at: string
  partnership_id: string
  accounts: { id: string; public_name: string }[]
  members: { id: string; name: string }[]
}

export interface PartnerTeamMember {
  name: string
  email: string
  cell_phone?: string | null
  status: string
  id: string
  user_id?: string | null
  type: string
  created_at: string
  teams: PartnerTeam[]
}

export interface TablePartnershipAd {
  id: string
  image_src: string
  link: string
  start_at: string
  end_at: string
  click_count: number
  partnership_id?: string
}

export type PartnershipAdValues = {
  id?: string
  image_src?: string
  link?: string
  start_at?: string
  end_at?: string
  partnership_id?: string
  finli_link?: string
}
