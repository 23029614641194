import AcceptInvoiceChangesDialog from "@/features/pay-invoice/AcceptInvoiceChangesDialog"
import ActivateCustomerDialog from "@/features/customer-management/components/ActivateCustomerDialog"
import BasicConfirmationDialog from "@/components/BasicConfirmationDialog"
import BulkAddTagsDialog from "@/features/customer-management/components/BulkAddTagsDialog"
import BulkSendEmailDialog from "@/features/customer-management/components/BulkSendEmailDialog"
import BulkUploadCustomersDialog from "@/features/customer-management/components/BulkUploadCustomersDialog"
import BulkUploadPriceItemsDialog from "@/features/customer-management/components/BulkUploadPriceItemsDialog"
import ChangePhoneNumberDialog from "@/features/user-management/components/ChangePhoneNumberDialog"
import CompleteComplianceDialog from "@/features/onboarding/CompleteComplianceDialog"
import ConfirmCancelInvoiceDialog from "@/features/invoice-management/components/ConfirmCancelInvoiceDialog"
import ConfirmCancelQuoteDialog from "@/features/quote-management/components/ConfirmCancelQuoteDialog"
import ConfirmCancelSeriesDialog from "@/features/invoice-management/components/ConfirmCancelSeriesDialog"
import ConfirmCustomerStatusChange from "@/features/customer-management/components/ConfirmCustomerStatusChange"
import ConfirmDeleteInvoiceDialog from "@/features/invoice-management/components/ConfirmDeleteInvoiceDialog"
import ConfirmDeleteQuoteDialog from "@/features/quote-management/components/ConfirmDeleteQuoteDialog"
import ConfirmSkipInvoiceDialog from "@/features/invoice-management/components/ConfirmSkipInvoiceDialog"
import CopyLinkDialog from "@/components/CopyLinkDialog"
import CustomerCategoryDialog from "@/features/customer-management/components/CustomerCategoryDialog"
import CustomerDialog from "@/features/customer-management/components/CustomerDialog"
import DeactivateCustomerDialog from "@/features/customer-management/components/DeactivateCustomerDialog"
import InviteMemberDialog from "@/features/account-management/components/InviteMemberDialog"
import InvoiceCustomerOptionsDialog from "@/features/invoice-management/components/InvoiceCustomerOptionsDialog"
import InvoiceSyncSaveConfirmationDialog from "../invoice-management/components/InvoiceSyncSaveConfirmationDialog"
import LeaveInvoiceConfirmationDialog from "@/features/invoice-management/components/LeaveInvoiceConfirmationDialog"
import NiceModal from "@ebay/nice-modal-react"
import PasswordVerificationDialog from "@/features/user-management/components/PasswordVerificationDialog"
import PriceEditDeleteDialog from "@/features/price-management/components/PriceEditDeleteDialog"
import PriceItemDialog from "@/features/price-management/components/PriceItemDialog"
import PriceModifierDialog from "@/features/price-management/components/PriceModifierDialog"
import QuoteSentConfirmationDialog from "@/features/quote-management/components/QuoteSentConfirmationDialog"
import RecordPaymentDialog from "@/features/payment-management/components/RecordPaymentDialog"
import RefundPaymentDialog from "@/features/payment-management/components/RefundPaymentDialog"
import SendInvoiceApprovalDialog from "@/features/invoice-management/components/SendInvoiceApprovalDialog"
import SendInvoiceConfirmationDialog from "@/features/invoice-management/components/SendInvoiceConfirmationDialog"
import SendQuoteApprovalDialog from "@/features/quote-management/components/SendQuoteApprovalDialog"
import SendQuoteConfirmationDialog from "@/features/quote-management/components/SendQuoteConfirmationDialog"
import ShareInvoiceConfirmationDialog from "@/features/invoice-management/components/ShareInvoiceConfirmationDialog"
import TaxDialog from "@/features/price-management/components/TaxDialog"
import WelcomeToFinliDialog from "@/components/WelcomeToFinliDialog"
import { DialogKeys } from "@/types"
import AddPriceModifierToInvoiceQuoteDialog from "@/features/builder/AddPriceModifierToInvoiceQuoteDialog"
import AddTaxToInvoiceQuoteDialog from "@/features/builder/AddTaxToInvoiceQuoteDialog"
import AddPriceItemToInvoiceQuoteDialog from "@/features/builder/AddPriceItemToInvoiceQuoteDialog"
import InvoiceQuotePriceItemOptionsDialog from "@/features/builder/InvoiceQuotePriceItemOptionsDialog"
import AddCustomerToInvoiceQuoteDialog from "@/features/builder/AddCustomerToInvoiceQuoteDialog"
import InvoiceQuotePreviewDialog from "@/features/builder/InvoiceQuotePreviewDialog"
import PartnershipAdDialog from "../admin-management/partner/PartnershipAdDialog"
import PufCreationDialog from "../admin-management/partner/PufCreationDialog"

NiceModal.register(DialogKeys.INVOICE_CUSTOMER_OPTIONS, InvoiceCustomerOptionsDialog)
NiceModal.register(DialogKeys.ACCEPT_INVOICE_CHANGES, AcceptInvoiceChangesDialog)

NiceModal.register(DialogKeys.INVOICE_QUOTE_CUSTOMER, AddCustomerToInvoiceQuoteDialog)
NiceModal.register(DialogKeys.INVOICE_QUOTE_PRICE_ITEM, AddPriceItemToInvoiceQuoteDialog)
NiceModal.register(DialogKeys.INVOICE_QUOTE_PRICE_ITEM_OPTIONS, InvoiceQuotePriceItemOptionsDialog)
NiceModal.register(DialogKeys.INVOICE_QUOTE_PRICE_MODIFIER, AddPriceModifierToInvoiceQuoteDialog)
NiceModal.register(DialogKeys.INVOICE_QUOTE_TAX, AddTaxToInvoiceQuoteDialog)
NiceModal.register(DialogKeys.INVOICE_QUOTE_PREVIEW, InvoiceQuotePreviewDialog)

NiceModal.register(DialogKeys.INVOICE_SYNC_SAVE_CONFIRMATION, InvoiceSyncSaveConfirmationDialog)

NiceModal.register(DialogKeys.PRICE_ITEM, PriceItemDialog)
NiceModal.register(DialogKeys.PRICE_MODIFIER, PriceModifierDialog)
NiceModal.register(DialogKeys.TAX, TaxDialog)
NiceModal.register(DialogKeys.PRICE_EDIT_DELETE, PriceEditDeleteDialog)
NiceModal.register(DialogKeys.CUSTOMER, CustomerDialog)
NiceModal.register(DialogKeys.CUSTOMER_CATEGORY, CustomerCategoryDialog)
NiceModal.register(DialogKeys.CUSTOMER_BULK_UPLOAD, BulkUploadCustomersDialog)
NiceModal.register(DialogKeys.PRICE_ITEM_BULK_UPLOAD, BulkUploadPriceItemsDialog)
NiceModal.register(DialogKeys.CUSTOMER_CONFIRM_STATUS, ConfirmCustomerStatusChange)
NiceModal.register(DialogKeys.CUSTOMER_BULK_TAGS, BulkAddTagsDialog)
NiceModal.register(DialogKeys.CUSTOMER_ACTIVATE, ActivateCustomerDialog)
NiceModal.register(DialogKeys.CUSTOMER_DEACTIVATE, DeactivateCustomerDialog)
NiceModal.register(DialogKeys.INVITE_MEMBER, InviteMemberDialog)
NiceModal.register(DialogKeys.SEND_INVOICE_CONFIRMATION, SendInvoiceConfirmationDialog)
NiceModal.register(DialogKeys.SHARE_INVOICE_CONFIRMATION, ShareInvoiceConfirmationDialog)
NiceModal.register(DialogKeys.SEND_INVOICE_APPROVAL, SendInvoiceApprovalDialog)
NiceModal.register(DialogKeys.LEAVE_INVOICE_CONFIRMATION, LeaveInvoiceConfirmationDialog)
NiceModal.register(DialogKeys.CANCEL_INVOICE_CONFIRMATION, ConfirmCancelInvoiceDialog)
NiceModal.register(DialogKeys.SKIP_INVOICE_CONFIRMATION, ConfirmSkipInvoiceDialog)
NiceModal.register(DialogKeys.DELETE_INVOICE_CONFIRMATION, ConfirmDeleteInvoiceDialog)
NiceModal.register(DialogKeys.CANCEL_SERIES_CONFIRMATION, ConfirmCancelSeriesDialog)
NiceModal.register(DialogKeys.COMPLETE_COMPLIANCE, CompleteComplianceDialog)
NiceModal.register(DialogKeys.CUSTOMER_BULK_EMAIL, BulkSendEmailDialog)
NiceModal.register(DialogKeys.CHANGE_PHONE_NUMBER, ChangePhoneNumberDialog)
NiceModal.register(DialogKeys.PASSWORD_VERIFICATION_DIALOG, PasswordVerificationDialog)
NiceModal.register(DialogKeys.CONFIRM, BasicConfirmationDialog)
NiceModal.register(DialogKeys.RECORD_PAYMENT, RecordPaymentDialog)
NiceModal.register(DialogKeys.REFUND_PAYMENT, RefundPaymentDialog)
NiceModal.register(DialogKeys.COPY_LINK_DIALOG, CopyLinkDialog)

NiceModal.register(DialogKeys.DELETE_QUOTE_CONFIRMATION, ConfirmDeleteQuoteDialog)
NiceModal.register(DialogKeys.CANCEL_QUOTE_CONFIRMATION, ConfirmCancelQuoteDialog)
NiceModal.register(DialogKeys.QUOTE_SENT, QuoteSentConfirmationDialog)
NiceModal.register(DialogKeys.SEND_QUOTE_CONFIRMATION, SendQuoteConfirmationDialog)
NiceModal.register(DialogKeys.SEND_QUOTE_APPROVAL, SendQuoteApprovalDialog)
NiceModal.register(DialogKeys.WELCOME_TO_FINLI, WelcomeToFinliDialog)
NiceModal.register(DialogKeys.PARTNERSHIP_AD, PartnershipAdDialog)

NiceModal.register(DialogKeys.ADMIN_PUF_CREATION, PufCreationDialog)
